<div class="row">
  <div class="col-lg-6">
    <div class="card dashboard-card">
      <div class="card-header">
        <div class="dashboard-card-title">Miles Driven</div>
      </div>
      <div class="card-body">
        <app-line-chart (onDataBound)="onDataBound($event)"></app-line-chart>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card dashboard-card">
      <div class="card-header">
        <div class="dashboard-card-title">Events Statistics</div>
      </div>
      <div
        class="card-body"
        *ngIf="isLoadDate"
      >
        <app-bar-stack-normalization-chart></app-bar-stack-normalization-chart>
      </div>
    </div>
  </div>
</div>
