<ng-template #template>
  <div
    *ngIf="isTooltipVisible"
    class="tooltip-container"
  >
    <svg-icon src="assets/images/icons/textIcon.svg"></svg-icon>
    <p class="tooltip-text">{{ text }}</p>
  </div>
</ng-template>

<div
  kendoTooltip
  showOn="none"
  filter=".k-grid td"
  [tooltipTemplate]="template"
  [closable]="true"
  (mouseenter)="toggleTooltip($event)"
  (mouseleave)="toggleTooltip($event)"
  [position]="position"
>
  <span
    class="dots"
    [attr.data-id]="elementId"
  >
    {{ text }}
  </span>
</div>
