import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    let api_key =
      this._authenticationService.getParamValueQueryString('api_key');
    if (api_key && api_key != '') {
      return true;
    }

    if (
      this._authenticationService.checkaccess(
        route.data['PermissionPageName'][0],
      )
    )
      return true;
    else {
      this._router.navigate(['/pages/miscellaneous/error']);
      return false;
    }
  }
}
