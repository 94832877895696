import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SafePipeModule } from 'safe-pipe';
import { TimeAgoPipe } from '../pipes/timeAgo.pipe';
import { AppMainContextComponent } from './app-main-context/app-main-context.component';
import { ButtonLoaderComponent } from './button-loader/button-loader.component';
import { ButtonComponent } from './button/button.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { GridCustomFilterComponent } from './grid-custom-filter/grid-custom-filter.component';
import { LoaderComponent } from './loader/loader.component';
import { SvgRendererComponent } from './svg-renderer/svg-renderer.component';
import { TelematicsEventsComponent } from './telematics-events/events.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { TextTooltipComponent } from './textTooltip/textTooltip.component';
import { ToasterComponent } from './toaster/toaster.component';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [
    SvgRendererComponent,
    ButtonComponent,
    TelematicsEventsComponent,
    LoaderComponent,
    GridCustomFilterComponent,
    TextFieldComponent,
    ToasterComponent,
    ConfirmationDialogComponent,
    TooltipComponent,
    TextTooltipComponent,
    ButtonLoaderComponent,
    AppMainContextComponent,
    TimeAgoPipe,
  ],
  imports: [
    ChatModule,
    InputsModule,
    CommonModule,
    IndicatorsModule,
    AngularSvgIconModule,
    FormsModule,
    NotificationModule,
    DialogsModule,
    DropDownsModule,
    PopupModule,
    TooltipsModule,
    SafePipeModule,
  ],
  exports: [
    SvgRendererComponent,
    ButtonComponent,
    TelematicsEventsComponent,
    LoaderComponent,
    GridCustomFilterComponent,
    TextFieldComponent,
    ToasterComponent,
    ConfirmationDialogComponent,
    TooltipComponent,
    TextTooltipComponent,
    ButtonLoaderComponent,
    DateInputsModule,
    IntlModule,
    DropDownsModule,
  ],
})
export class ComponentsModule {}
