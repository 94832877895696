import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Component, Injectable, ViewChild } from '@angular/core';

import { concat, delay, Observable, of } from 'rxjs';

import { MainLayoutComponent } from './layout/main-layout/layout/main-layout.component';
import { AppMainContextComponent } from './shared/components/app-main-context/app-main-context.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ToasterComponent } from './shared/components/toaster/toaster.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends AppMainContextComponent {
  @ViewChild(MainLayoutComponent) mainLayoutComponent!: MainLayoutComponent;
  @ViewChild(LoaderComponent) loaderComponent!: LoaderComponent;
  @ViewChild(ToasterComponent) toasterComponent!: ToasterComponent;

  ngAfterViewInit() {
    this._authenticationService.onGetGoogleAPIKey.subscribe(isSuccessLogin => {
      if (isSuccessLogin) {
        this.getGoogleAPIKey();
      }
    });
    if (
      this._authenticationService.currentUserValue != null &&
      Object.keys(this._authenticationService.currentUserValue).length > 0
    ) {
      this.getGoogleAPIKey();
    }
  }

  getGoogleAPIKey() {
    this._authenticationService.getGoogleAPIKey().subscribe(result => {
      let key = result.data.propertyValue;
      this.loadScript(
        `https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=${key}`,
      ).then(() => {
        console.log('Init Google Maps Success');
      });
    });
  }

  loadScript(name: string) {
    return new Promise<void>((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = name;
      document.getElementsByTagName('head')[0].appendChild(script);
      console.log('Script Loaded');
      resolve();
    });
  }
}

/*
  Mocked backend service.
  For further details, check
  https://angular.io/guide/http#writing-an-interceptor
*/

@Injectable()
export class UploadInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.url === 'saveUrl') {
      const events: Observable<HttpEvent<unknown>>[] = [0, 25, 50, 75, 100].map(
        x =>
          of(<HttpProgressEvent>{
            type: HttpEventType.UploadProgress,
            loaded: x,
            total: 100,
          }).pipe(delay(350)),
      );

      const success = of(new HttpResponse({ status: 200 })).pipe(delay(350));
      events.push(success);

      return concat(...events);
    }

    if (req.url === 'removeUrl') {
      return of(new HttpResponse({ status: 200 }));
    }

    return next.handle(req);
  }
}
