import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RideDetail } from '../../../interfaces/ride-data/ride-list.interface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-telematics-section-details',
  templateUrl: './telematics-section-details.component.html',
  styleUrls: ['./telematics-section-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TelematicsSectionDetailsComponent {
  constructor(public datepipe: DatePipe) {}

  @Input('RideDetail') rideDetailsResult: RideDetail;

  public getRideEventValue(eventTypeId: number) {
    let value: number = 100;
    let data = this.rideDetailsResult?.rideEvents.filter(
      a => a.eventTypeId == eventTypeId,
    )[0];
    if (data) {
      value = data.percentage;
    }
    return value;
  }

  public splitTime(numberOfHours?: number): string {
    let days: number = 0;
    let hourss: number = 0;
    let mins: number = 0;
    if (numberOfHours) {
      if (Math.sign(numberOfHours) != -1) {
        mins = numberOfHours % 60;
        let hours = Math.floor(numberOfHours / 60);
        days = Math.floor(hours / 24);
        hourss = hours % 24;
      } else {
        let absTotal = Math.abs(numberOfHours);
        mins = absTotal % 60;
        let hours = Math.floor(absTotal / 60);
        days = Math.floor(hours / 24);
        hourss = hours % 24;
      }
    }
    let daysValue = days == 0 ? '' : `${days} d`;
    let hoursValue = hourss == 0 ? '' : `${hourss} h`;
    let minutesValue = mins == 0 ? '' : `${mins} m`;

    return `${daysValue} ${hoursValue} ${minutesValue}`;
  }
}
