import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  @Output() submitDialog = new EventEmitter<void>();
  @Input() opened = false;
  @Input() type: string = '';
  @Input() confirmationText: string = '';
  @Input() hintText: string = '';
  public districts = [
    {
      name: 'hammed',
      value: 4,
    },
  ];

  public close(): void {
    this.opened = false;
  }
  public open(): void {
    this.opened = true;
  }
  public submit(): void {
    this.submitDialog.emit();
  }
}
