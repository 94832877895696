import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PermissionsList } from 'src/app/enums/Permissions/PermissionList';
import { User } from 'src/app/interfaces/authentication/user.interface';
import { CoreMenu } from 'src/app/interfaces/core-menu-data/core-menu-data.interface';
import { environment } from 'src/environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public onGetGoogleAPIKey: EventEmitter<boolean> = new EventEmitter();
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  access_token: any;
  domain: string;
  userData: any;
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _http: HttpClient,
    private _router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')!),
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.domain =
      environment.localDomain ??
      this.document.location.protocol + '//' + this.document.location.hostname;
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')!),
    );
    this.currentUser = this.currentUserSubject.asObservable();
    return this.currentUserSubject.value;
  }

  LoggedIn() {
    return !!localStorage.getItem('currentUser');
  }

  logout() {
    let that = this;
    localStorage.removeItem('User_Data');
    localStorage.removeItem('currentUser');
    sessionStorage.clear();
    sessionStorage.removeItem('currentMenu');
    that._router.navigate(['/pages/authentication/login']);
  }

  clearCurrentUser() {
    // remove user from session storage to log user out\
    localStorage.removeItem('User_Data');
    localStorage.removeItem('currentUser');
    sessionStorage.clear();
    sessionStorage.removeItem('currentMenu');
  }

  checkaccess(pagename: string): boolean {
    let userinfo = JSON.parse(localStorage.getItem('currentUser')!) as User;
    if (userinfo != null) {
      if (this.getParamValueQueryString('api_key') == null) {
        if (
          userinfo.permissionList.includes(
            PermissionsList[pagename as keyof typeof PermissionsList],
          )
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  checkParantAccess(pagesname: string[]): boolean {
    let userinfo = JSON.parse(localStorage.getItem('currentUser')!) as User;
    if (userinfo != null && Object.keys(userinfo).length > 0) {
      if (this.getParamValueQueryString('api_key') == null) {
        for (const element of userinfo.permissionList) {
          for (let name of pagesname) {
            if (PermissionsList[parseInt(element.toString())] == name) {
              return true;
            }
          }
        }
      } else {
        return true;
      }
    }
    return false;
  }

  getDefaultPage(array: CoreMenu[]): string {
    let userinfo = JSON.parse(localStorage.getItem('currentUser')!) as User;
    if (userinfo != null && Object.keys(userinfo).length > 0) {
      if (this.getParamValueQueryString('api_key') == null) {
        return this.getDefaultPageProcess(array, userinfo);
      }
    }
    return '/';
  }

  getDefaultPageProcess(array: CoreMenu[], userinfo: User): any {
    for (const element of array) {
      if (element.children?.length)
        for (let children of element.children) {
          if (children.permission) {
            if (
              userinfo.permissionList.includes(
                PermissionsList[
                  children.permission as keyof typeof PermissionsList
                ],
              )
            ) {
              return '/' + children.url;
            }
          } else {
            for (let childrenNested of children.children!) {
              if (
                userinfo.permissionList.includes(
                  PermissionsList[
                    childrenNested.permission as keyof typeof PermissionsList
                  ],
                )
              ) {
                return '/' + childrenNested.url;
              }
            }
          }
        }
      else if (
        userinfo.permissionList.includes(
          PermissionsList[element.permission as keyof typeof PermissionsList],
        )
      )
        return `/${element.url}`;
    }
  }

  saveUserData(userinfo: User) {
    localStorage.setItem('currentUser', JSON.stringify(userinfo));
  }

  ApiLogin() {
    return this._http.post<any>(
      environment.mainapiUrl + '/Identity/DispatcherLogin',
      {
        email: this.userData['preferred_username'],
      },
    );
  }

  CheckEmail(email: string, id?: number) {
    return this._http.get<any>(
      environment.mainapiUrl +
        `/Identity/CheckIfEmailExist/${email}` +
        (id ? `?id=${id}` : ''),
    );
  }

  login(data: any) {
    return this._http.post<any>(
      `${environment.mainapiUrl}/authentication/login`,
      data,
    );
  }

  getRide(data: any) {
    return this._http.post<any>(`${environment.mainapiUrl}/rides/get`, data);
  }

  getParamValueQueryString(paramName: string) {
    let currentUser = {} as User;
    const url: string = this.document.location.href;
    let paramValue: any;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
      currentUser.apiKey = paramValue;
      this.saveUserData(currentUser);
    } else if (this.currentUserValue) {
      paramValue = this.currentUserValue.apiKey;
    }
    return paramValue;
  }

  refreshAccessToken() {
    let that = this;
    let user = that.currentUserValue;
    return this._http.post<any>(
      `${environment.mainapiUrl}/authentication/refresh-token`,
      { accessToken: user.token, refreshToken: user.refreshToken },
    );
  }

  getGoogleAPIKey() {
    let propertyName = 'GoogleAPIKeySafetyUI';
    return this._http.get<any>(
      `${environment.mainapiUrl}/configurations/${propertyName}`,
    );
  }
  GetGeoCode(latitude: any, longitude: any) {
    let addressModel = {
      address: {
        latitude: latitude,
        longitude: longitude,
      },
      addressName: ''
    };
    return this._http.post<any>(
      `${environment.mainapiUrl}/google-map/geocode`,
      addressModel,
    );
  }
}
