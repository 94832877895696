<div class="login">
  <div class="login-area">
    <div class="login-card">
      <div class="card-body w-100">
        <a
          href="javascript:void(0);"
          class="brand-logo"
        >
          <img
            src="assets/images/icons/FullLogo.png"
            alt="brand-logo"
            height="40.5"
          />
        </a>
        <p class="bold f-30 text-primary mb-12 text-left">Welcome</p>
        <p class="text-secondary f-18 mb-58 text-left">
          Please sign-in to your account and start the adventure.
        </p>

        <form
          class="auth-login-form mt-2"
          [formGroup]="loginForm"
        >
          <div class="form-group">
            <label
              for="Username"
              class="form-label"
              >Username</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Username"
              aria-describedby="login-Username"
              tabindex="1"
              formControlName="Username"
              autofocus
              autocomplete="Username"
              [ngClass]="{ 'is-invalid': submitted }"
              data-automation-id="login-Username"
            />
            <div
              *ngIf="submitted"
              class="invalid-feedback"
            >
              <div>Username is required</div>
            </div>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="Password">Password</label>
            </div>
            <div
              class="input-group input-group-merge form-password-toggle input-group-focus"
            >
              <input
                type="password"
                class="form-control form-control-merge"
                tabindex="2"
                formControlName="Password"
                placeholder="Password"
                aria-describedby="login-password"
                autocomplete="Password"
                data-automation-id="login-password"
                [ngClass]="{ 'is-invalid error': submitted }"
              />
            </div>
            <div
              *ngIf="submitted"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted }"
            >
              <div>Password is required</div>
            </div>
          </div>

          <button
            class="btn btn-info btn-block btn-login"
            (click)="onSubmit($event)"
          >
            SIGN IN
          </button>
        </form>

        <div>
          <ul class="login-list">
            <li>
              Privacy
              <img
                alt="link-arrow"
                width="14.4"
                height="14.4"
                src="./assets/images/icons/link-arrow.svg"
              />
            </li>
            <li class="separator">|</li>
            <li>
              Terms
              <img
                alt="link-arrow"
                width="14.4"
                height="14.4"
                src="./assets/images/icons/link-arrow.svg"
              />
            </li>
            <li class="separator">|</li>
            <li>
              Help
              <img
                alt="link-arrow"
                width="14.4"
                height="14.4"
                src="./assets/images/icons/link-arrow.svg"
              />
            </li>
          </ul>
        </div>
        <div class="CopyRight">
          Copyright
          <img
            alt="CopyRight"
            width="14.4"
            height="14.4"
            src="./assets/images/icons/CopyRight.svg"
          />
          2024 Adroit Advanced Technologies Inc.
        </div>
      </div>
    </div>
  </div>
</div>
