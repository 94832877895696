<kendo-grid [data]="gridData"
            [pageSize]="state.take"
            [skip]="state.skip"
            [sortable]="true"
            [sort]="state.sort"
            [filter]="state.filter"
            style="height: 90%"
            kendoGridSelectBy="id"
            [(selectedKeys)]="selectedRows"
            [selectable]="true"
            [columnMenu]="{ filter: true, columnChooser: false }"
            (dataStateChange)="dataStateChange($event)"
            [pageable]="pagerSettings || defaultPagerSettings"
            (pageChange)="clearMySelection()"
            style="max-height: 80%">
  <ng-template kendoGridToolbarTemplate>
    <div class="input-wrapper" >
      <input placeholder="Search in all columns"
             [(ngModel)]="searchQuery"
             (keyup)="filterAmongColumns($event)"
             ((keypress))="onType()"
             class="search-input"
             [class.has-text]="searchQuery !== ''" />
      <div class="input-line"></div>
      <app-svg-renderer icon="search"
                        [width]="14.4"
                        [height]="14.4"
                        [class]="'search-icon'">
      </app-svg-renderer>
    </div>

    <div class="input-wrapper"  >
      <kendo-datepicker class="datepicker-custom-field"
                        placeholder="Date of Service"
                        [(ngModel)]="formattedSelectedDate"
                        [popupSettings]="{ popupClass: 'datepicker_custom_popup' }"
                        (valueChange)="onDateChange($event)"
                        [disabledDates]="disabledDates"></kendo-datepicker>
    </div>

    <div class="input-wrapper" >
      <kendo-dropdownlist #dropdownlistDriverName
                          [popupSettings]="{ popupClass: 'dropDown_custom_popup' }"
                          [data]="driversList"
                          [kendoDropDownFilter]="filterSettings"
                          textField="fullName"
                          valueField="id"
                          name="driverId"
                          id="driverId"
                          [(ngModel)]="driverId"
                          [valuePrimitive]="true"
                          [defaultItem]="{
          fullName: 'Driver Name',
          value: null,
          disable: true
        }"
                          (valueChange)="valueChangeDriverId($event)"
                          [itemDisabled]="itemDisabled"
                          class="dropDown_custom_field">
      </kendo-dropdownlist>
    </div>

    <div class="input-wrapper" >
      <kendo-dropdownlist #dropdownlistRideId
                          [popupSettings]="{ popupClass: 'dropDown_custom_popup' }"
                          [data]="rideList"
                          [kendoDropDownFilter]="filterSettings"
                          textField="id"
                          valueField="id"
                          name="rideId"
                          id="rideId"
                          [(ngModel)]="rideId"
                          [valuePrimitive]="true"
                          [defaultItem]="{
          id: 'Ride ID',
          value: null,
          disable: true
        }"
                          (valueChange)="valueChangeRideId($event)"
                          [itemDisabled]="itemDisabled"
                          class="dropDown_custom_field">
      </kendo-dropdownlist>
    </div>

    <kendo-grid-spacer></kendo-grid-spacer>

    <div class="filter-buttonsContainer">
      <app-button class="optional"
                  sizeClass="xs"
                  [disabled]="false"
                  (onClick)="Clear()">Clear</app-button>
      <app-button [disabled]="disabelSearch"
                  class="light"
                  sizeClass="xs"
                  (onClick)="Search()">Search</app-button>
    </div>
  </ng-template>
  <kendo-grid-column [width]="45"
                     [sortable]="false"
                     title="#">
    <ng-template kendoGridCellTemplate
                 let-rowIndex="rowIndex+1">
      {{ rowIndex }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngFor="let item of gridSchema?.cols; index as i"
                     field="{{ item.key }}"
                     title="{{ item.displayName }}"
                     [sortable]="item.isSortable"
                     [filterable]="item.isFilterable"
                     [width]="item.width*0.9"
                     [headerClass]="{ myCursor: true }"
                     >
    <ng-template kendoGridHeaderTemplate
                 let-column
                 let-columnIndex="columnIndex"
                 let-dataItem>
      {{ column.title }}
      <a *ngIf="checkColumnFilter(dataItem[item.key])"
         (click)="clearColumnFilter(dataItem[item.key])"
         class="btn-clear-column-filter k-input-button k-button k-icon-button k-button-md k-button-solid k-button-solid-base">
        <span class="k-icon k-i-filter-clear"></span>
      </a>
    </ng-template>

    <ng-template kendoGridCellTemplate
                 let-rowIndex="rowIndex+1"
                 let-dataItem>
      <ng-container *ngIf="dataItem[item.key] && item.hasTooltipCell; else checkBadgeItem">
        <ng-container *ngIf="item.isImageTooltip; else displayNormalTooltip">
          <!-- Display tooltip with image -->
          <app-tooltip [imageUrl]="dataItem[item.key]"></app-tooltip>
        </ng-container>

        <ng-template #displayNormalTooltip>
          <!-- Display normal text tooltip -->
          <app-textTooltip elementId="{{ item.key + '_' + dataItem.id }}"
                           [text]="dataItem[item.key]">
          </app-textTooltip>
        </ng-template>
      </ng-container>
      <ng-template #checkBadgeItem>
        <ng-container *ngIf="
            item.hasTelematicCell;
            then telematicCell;
            else telematicEventCell
          "></ng-container>
        <ng-template #telematicCell>
          <button id="Telematic"
                  style="
              border: 0.45px;
              border-color: transparent;
              background-color: transparent;
            "
                  (click)="navigateToMap(dataItem)">
            <img alt="Icon-telematics"
            style="width: 22px;height: 21px;"
                 src="assets/images/icons/Icon-telematics.png" />
          </button>
        </ng-template>
        <ng-template #telematicEventCell>
          <ng-container *ngIf="
              item.hasTelematicEventCell;
              then telematicEventCellOutput;
              else checkActionCellItem
            "></ng-container>
          <ng-template #telematicEventCellOutput>
            <app-telematics-events [events]="dataItem[item.key]"></app-telematics-events>
          </ng-template>
        </ng-template>
      </ng-template>

      <ng-template #checkActionCellItem>
        <button *ngIf="
            dataItem[item.key] &&
              item.hasActionCell &&
              item.actionCellType &&
              item.actionCellIconName;
            else displayNormalCellItem
          "
                type="button"
                kendoGridEditCommand
                (click)="triggerAction(item.actionCellType, dataItem)">
          <app-svg-renderer [icon]="item.actionCellIconName"
                            [width]="12.6"
                            [height]="12.6"
                            [class]="'sidebarIcon'" />
        </button>
      </ng-template>

      <ng-template #displayNormalCellItem>
        <span *ngIf="item.format == null; else withFormatCellItem">
          {{
            dataItem[item.key] || dataItem[item.key] === 0
              ? dataItem[item.key] + "" + (item.valueIcon ? item.valueIcon : "")
              : "N/A"
          }}
        </span>
      </ng-template>
      <ng-template #withFormatCellItem>
        {{
          dataItem[item.key] || dataItem[item.key] === 0
            ? (dataItem[item.key] | date: item.format)
            : "N/A"
        }}
      </ng-template>
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate
                 let-filter
                 let-column="column">
      <grid-custom-filter [FilterValus]="this.filtersList[i]"
                          (Applyfilters)="getFilterValue($event)"
                          (ClearFilter)="clearColumnFilter($event)">
      </grid-custom-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column *ngIf="gridSchema.actions?.length"
                             title="Actions"
                             [width]="actionsColumnWidth*0.9"
                             [headerClass]="{ myCursor: true }">
    <ng-template kendoGridCellTemplate
                 let-dataItem>
      <div class="edit-buttons">
        <div *ngFor="let item of gridSchema.actions">
          <!-- Check if item has sub menu items -->
          <div *ngIf="
              item.hasSubMenuItems && (item.subMenuItems || []).length;
              else checkUrlAction
            "
               ngbDropdown
               container="body"
               placement="bottom-left">
            <!-- Dropdown Toggle Button -->
            <button type="button"
                    ngbDropdownToggle
                    class="btn-chat-action">
              <app-svg-renderer *ngIf="item.subMenuCustomIcon; else displayDefaultSubMenuIcon"
                                [icon]="item.subMenuCustomIcon"
                                [width]="12.6"
                                [height]="12.6"
                                [class]="'sidebarIcon'" />

              <!-- Default Icon for Sub Menu -->
              <ng-template #displayDefaultSubMenuIcon>
                <i class="fas fa-ellipsis-h"></i>
              </ng-template>
            </button>

            <!-- Sub Menu Items -->
            <div ngbDropdownMenu
                 aria-labelledby="action-item-sub-menu-ddl"
                 class="msg-dropdowm-action position"
                 style="right: -16.2px; top: -4.5px">
              <ng-container *ngFor="let subMenuItem of item.subMenuItems">
                <button *ngIf="
                    subMenuItem.visible ? subMenuItem.visible(dataItem) : true
                  "
                        type="button"
                        ngbDropdownItem
                        (click)="
                    triggerAction(
                      subMenuItem.actionName,
                      dataItem,
                      subMenuItem.additionalEntities
                    )
                  "
                        [class.disabled]="
                    subMenuItem.disabled
                      ? subMenuItem.disabled(dataItem)
                      : false
                  ">
                  {{ subMenuItem.label }}
                </button>
              </ng-container>
            </div>
          </div>

          <!-- Check if item is a URL -->
          <ng-template #checkUrlAction>
            <button *ngIf="item.isURL; else displayNormalActionCellItem"
                    [ngClass]="item.customClass || ''"
                    kendoGridEditCommand
                    [routerLink]="[item.url, dataItem.id]">
              <app-svg-renderer [icon]="item.icon"
                                [width]="12.6"
                                [height]="12.6"
                                [class]="'sidebarIcon'" />
            </button>
          </ng-template>

          <!-- Normal Action Cell Item -->
          <ng-template #displayNormalActionCellItem>
            <button *ngIf="item.isAction && item.actionName"
                    [ngbTooltip]="item.tooltipText ? item.tooltipText : null"
                    [tooltipClass]="item.tooltipText ? 'grid-action-tooltip' : ''"
                    [ngClass]="item.customClass || null"
                    kendoGridEditCommand
                    (click)="triggerAction(item.actionName, dataItem)">
              <app-svg-renderer [icon]="item.icon"
                                [width]="12.6"
                                [height]="12.6"
                                [class]="'sidebarIcon'" />
            </button>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </kendo-grid-command-column>

  <ng-template kendoPagerTemplate
               let-totalPages="totalPages"
               let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="5">
    </kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
    <kendo-pager-info></kendo-pager-info>
  </ng-template>
  <kendo-grid-excel [fileName]="pageName + '.xlsx'"
                    [fetchData]="getExcelData">
  </kendo-grid-excel>
  <kendo-grid-messages pagerItemsPerPage="Records per page"
                       [pagerItems]="recordLabel"
                       pagerOf="of">
  </kendo-grid-messages>
</kendo-grid>
