import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../shared/components/components.module';
import { MainlayoutModule } from './main-layout/mainlayoutModule';
import { LayoutComponentsModule } from './Components/layoutComponents.module';
@NgModule({
  declarations: [],
  exports: [MainlayoutModule, LayoutComponentsModule],
  imports: [
    BrowserModule,
    RouterModule,
    ComponentsModule,
    MainlayoutModule,
    LayoutComponentsModule,
  ],
})
export class LayoutModule {}
