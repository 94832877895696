import { Component, OnInit } from '@angular/core';

import { interval } from 'rxjs';

@Component({
  selector: 'app-current-date-time',
  templateUrl: './current-date-time.html',
  styleUrls: ['./current-date-time.css'],
})
export class CurrentDateTime implements OnInit {
  currentDate: string | undefined;
  currentTime: string | undefined;
  timezoneOffset: number;

  constructor() {
    // Set the initial timezone offset to the user's local timezone
    this.timezoneOffset = new Date().getTimezoneOffset() / 60;
  }

  ngOnInit(): void {
    // Update the current date and time every second
    this.getCurrentDateAndTime();

    // Use RxJS interval to update the date and time every second
    const source = interval(1000);
    source.subscribe(() => {
      this.getCurrentDateAndTime();
    });
  }

  getCurrentDateAndTime() {
    const date = new Date();
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayNumber = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const gmtOffset = this.timezoneOffset;
    const gmtSign = gmtOffset > 0 ? '-' : '+';
    const gmtHours = Math.abs(gmtOffset);

    // Convert hours to 12-hour  format
    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours = hours - 12;
    }

    this.currentDate = `${day}, ${month} ${dayNumber}, ${year}`;
    this.currentTime = `${hours}:${minutes} ${ampm} (GMT${gmtSign}${gmtHours})`;
  }

  onChangeTimezone(offset: number) {
    // Update the timezone offset when user selects a new timezone
    this.timezoneOffset = offset;
    this.getCurrentDateAndTime();
  }
}
