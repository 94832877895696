import { Component, Input } from '@angular/core';
import { TelematicsEvent } from '../../../enums/events/TelematicsEvent';

@Component({
  selector: 'app-telematics-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class TelematicsEventsComponent {
  @Input() events: Array<any>;

  public setStatusIcon(value: number): string {
    let text = '';
    switch (value) {
      case TelematicsEvent.Acceleration:
        text = 'icon-acceleration';
        break;
      case TelematicsEvent.HardBraking:
        text = 'icon-hard-braking';
        break;
      case TelematicsEvent.HardReturn:
        text = 'icon-hard-return';
        break;
      case TelematicsEvent.PhoneUsed:
        text = 'icon-phone-used';
        break;
      case TelematicsEvent.SpeedLimit:
        text = 'icon-speed-limit';
        break;
    }
    return text;
  }
}
