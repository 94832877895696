import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TabsComponent } from '../../components/app-tabs/app-tabs.component';
import { Ride } from '../../interfaces/ride-data/ride-list.interface';
import { RideNavigationService } from '../../services/rides/ride-navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent {
  public selectedTab: string;
  public rideData: Ride = new Ride();
  public currentPathIndex: number = 0;
  @ViewChild(TabsComponent) tabsComponent!: TabsComponent;
  constructor(
    private router: Router,
    private _rideNavigationService: RideNavigationService,
  ) {
    this._rideNavigationService.onChangeData.subscribe((result: Ride) => {
      this.rideData = result;
      this.tabsComponent.selectTabByIndex(2, false);
    });
  }

  public onTabSelectionChange(data: any) {
    this.selectedTab = data.tab.tabName;
    if (this.selectedTab === 'Overview') {
      this.currentPathIndex = 0;
    }
    if (this.selectedTab === 'Tracking') {
      this.currentPathIndex = 1;
    }
    if (this.selectedTab === 'Telematics') {
      this.currentPathIndex = 2;
    }
    if (data.isOriginal) {
      this.rideData = new Ride();
    }
  }
}
