import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  @Input() imageUrl: string;

  showToolTip(e: MouseEvent) {
    const element = e.target as HTMLElement;
    this.tooltipDir.toggle(element);
  }

  hideToolTip(e: MouseEvent) {
    this.tooltipDir.hide();
  }
}
