import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent {
  @Input() icon: string;
  @Input() id: string;
  @Input() bindingValue: any;
  @Input() placeholder: string;
  @Input() invalid: boolean;
  @Input() ariaLabel: string;
  @Input() formControlName: string;
  @Input() name: string;
  @Output() bindingValueChange = new EventEmitter<string>();

  onInputChange(newValue: string) {
    this.bindingValue = newValue;
    this.bindingValueChange.emit(newValue);
  }
}
