import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { FilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'grid-custom-filter',
  templateUrl: './grid-custom-filter.component.html',
  styleUrls: ['./grid-custom-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridCustomFilterComponent {
  ddlFilterpopup = 'ddlFilterpopup';

  public filterData: Array<string> = ['contains', 'startswith'];

  @Input()
  FilterValus: FilterDescriptor;

  @Output('Applyfilters')
  filterApplied = new EventEmitter<FilterDescriptor>();

  @Output('ClearFilter')
  clearColumnFilter = new EventEmitter<string>();

  public ApplycolumnFilter(): void {
    this.filterApplied.emit(this.FilterValus);
  }

  public ClearFilter(): void {
    this.clearColumnFilter.emit(this.FilterValus.field?.toString());
  }
}
