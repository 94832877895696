<button
  (click)="handleClick()"
  #button
  [ngClass]="[class, sizeClass]"
  [ngStyle]="{ width: buttonWidth }"
  class="btn"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</button>
