import { Component, TemplateRef, ViewChild } from '@angular/core';

import {
  NotificationService,
  type Type,
  type Animation,
} from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
})
export class ToasterComponent {
  @ViewChild('template', { read: TemplateRef })
  public notificationTemplate: TemplateRef<unknown>;

  public message: string = '';

  constructor(private notificationService: NotificationService) {}

  public showSuccess(massage: string): void {
    this.message = massage;
    this.showNotification('success', 'slide', 500);
  }

  public showError(massage: string): void {
    this.message = massage;
    this.showNotification('error', 'slide', 500);
  }

  public showWarning(massage: string): void {
    this.message = massage;
    this.showNotification('warning', 'fade', 500);
  }

  public showInfo(massage: string): void {
    this.message = massage;
    this.showNotification('info', 'fade', 500);
  }

  private showNotification(
    typeStyle: Type['style'],
    animationType: Animation['type'],
    duration: number,
  ): void {
    this.notificationService.show({
      content: this.notificationTemplate,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: animationType, duration: duration },
      type: { style: typeStyle, icon: true },
      hideAfter: 2000,
    });
  }
}
