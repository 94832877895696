<kendo-dialog
  *ngIf="opened"
  (close)="close()"
  class="confirmationDialog"
  [ngClass]="{
    SaveDialog: type == 'Save',
    DeleteDialog: type == 'Delete',
    AddDialog: type == 'Add',
    SuccessDialog: type == 'Success',
    WarningDialog: type == 'Warning'
  }"
>
  <kendo-dialog-titlebar>
    <div style="font-size: 16.2px; line-height: 1.3em">
      <div class="iconWraber">
        <svg-icon
          [src]="
            type == 'Delete'
              ? 'assets/images/icons/Trash.svg'
              : type == 'Save'
              ? 'assets/images/icons/Save.svg'
              : type == 'Success'
              ? 'assets/images/icons/Chield Check.svg'
              : type == 'Warning'
              ? 'assets/images/icons/exclamation-circle 1.svg'
              : 'assets/images/icons/Add.svg'
          "
          [svgStyle]="{ 'width.px': 21.6, 'height.px': 21.6 }"
          class="deleteIcon"
        ></svg-icon>
      </div>
    </div>
  </kendo-dialog-titlebar>

  <span class="confirmationText">
    {{ confirmationText }}
  </span>
  <span class="hintText">
    {{ hintText }}
  </span>
  <div class="buttonsContainer">
    <app-button
      class="optional"
      sizeClass="sm"
      (click)="close()"
      >Cancel</app-button
    >
    <app-button
      class="destructive"
      sizeClass="sm"
      (click)="submit()"
      >{{
        type == "Warning" ? "Continue" : type == "Delete" ? "Delete" : "Save"
      }}</app-button
    >
  </div>
</kendo-dialog>
