import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as echarts from 'echarts';
import { map } from 'rxjs';
import { AppComponent } from '../../../app.component';
import {
  MileDriven,
  MileDrivenData,
} from '../../../interfaces/ride-data/mile-drive.interface';
import { RidesService } from '../../../services/rides/rides-list.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css'],
})
export class LineChartComponent implements OnInit {
  @Output() onDataBound = new EventEmitter<boolean>();

  constructor(
    private _ridesService: RidesService,
    private _appComponent: AppComponent,
  ) {}

  ngOnInit(): void {
    this.getMilesDriven();
  }

  getMilesDriven() {
    this._appComponent.loaderComponent.toggleLoaderVisibility();
    this._ridesService
      .getMilesDriven()
      .pipe(map((res: MileDriven) => res))
      .subscribe({
        next: (response: MileDrivenData) => {
          let days: any = response.data.map(a => a.dateOfService);
          let data: any = response.data.map(a => a.distance.toFixed(2));
          type EChartsOption = echarts.EChartsOption;

          let chartDom = document.getElementById('line-chart')!;
          let myChart = echarts.init(chartDom);

          let option: EChartsOption = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#283B56',
                },
              },
            },
            xAxis: {
              type: 'category',
              data: days,
            },
            yAxis: {
              type: 'value',
            },
            series: [
              {
                data: data,
                type: 'line',
              },
            ],
          };
          option && myChart.setOption(option);
          this.onDataBound.emit(true);
        },
        error: (error: HttpErrorResponse) => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
        complete: () => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
      });
  }
}
