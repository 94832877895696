import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable()
export class DriversService {
  constructor(private http: HttpClient) {}

  public syncDriversList(): any {
    return this.http.get(`${environment.mainapiUrl}/drivers`);
  }
}
