import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { UnauthorizedTypes } from 'src/app/enums/Permissions/UnauthorizedTypes';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private timeOut = 10;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );
  /**
   * @param {Router} _router
   * @param {AuthenticationService} authService
   */
  constructor(
    private authService: AuthenticationService,
    private _router: Router,
  ) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let that = this;
    const currentUser = this.authService.currentUserValue;
    const isLoggedIn = currentUser?.token ?? false;
    const isApiUrl = request.url.startsWith(environment.mainapiUrl);
    const apiKey = this.authService.getParamValueQueryString('api_key');
    if (apiKey && apiKey != '') {
      request = request.clone({ setHeaders: { ApiKey: `${apiKey}` } });
    } else if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `${currentUser.token}`,
          IdToken: currentUser.idToken,
        },
      });
    }
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (
            error.error &&
            (error.error.data || error.error.Data || error.error)
              .unauthorizedType == UnauthorizedTypes.InvalidCredentials
          ) {
            this.authService.clearCurrentUser();
            that.authService.logout();
            return next.handle(request);
          } else return this.handleAuthorizationError(request, next);
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  private handleAuthorizationError(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.tokenSubject.next(null);
      return this.authService.refreshAccessToken().pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          if (response.data) {
            const currentUser = this.authService.currentUserValue;
            currentUser.refreshToken = response.data.refreshToken;
            currentUser.token = response.data.accessToken;
            this.authService.saveUserData(currentUser);
            this.tokenSubject.next(request);
          } else {
            // Invalid refresh token
            this.authService.clearCurrentUser();
            this._router.navigate(['/pages/authentication/login']);
            return throwError(() => 'Invalid or expired refresh token.');
          }
          return next.handle(this.setToken(request));
        }),
        finalize(() => {
          this.isRefreshing = false;
        }),
      );
    } else {
      return this.tokenSubject.pipe(
        switchMap(newRequest => {
          return next.handle(newRequest);
        }),
      );
    }
  }

  private setToken(request: HttpRequest<any>) {
    const currentUser = this.authService.currentUserValue;
    return request.clone({
      setHeaders: {
        Authorization: currentUser.token,
        IdToken: currentUser.idToken,
      },
    });
  }
}
