import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './app-tab.component.html',
  styleUrls: ['./app-tab.component.scss'],
})
export class TabComponent {
  @Input() tabName: string;
  @Input() shouldPreRender: boolean = false;

  isActive: boolean = false;
}
