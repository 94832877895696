<ul
  class="tab-list-container"
  [ngClass]="{ 'settings-tabs': isSettingsPage }"
>
  <li
    *ngFor="let tab of tabs; let i = index"
    (click)="selectTabByIndex(i, true)"
    [class.active]="tab.isActive"
    class="tab-category-wrapper"
    [style.color]="tabCategoryTextColor"
    [style.backgroundColor]="
      tab.isActive ? activeTabCategoryBackgroundColor : ''
    "
  >
    {{ tab.tabName }}
  </li>
</ul>
<div
  class="tab-category-content-container"
  [ngClass]="{
    'custom-border': tabs.first && tabs.first.isActive,
    'settings-tab-category': isSettingsPage
  }"
  [style.backgroundColor]="contentContainerBackgroundColor"
  [style.padding]="contentContainerPadding"
>
  <ng-content></ng-content>
</div>
