<div
  class="avatar-container"
  (click)="onToggleMenu()"
>
  <kendo-avatar
    [imageSrc]="img1"
    width="100%"
    height="100%"
  >
  </kendo-avatar>
  <div
    class="avatarMenuContainer"
    *ngIf="showMenu"
  >
    <ul class="menu">
      <li
        *ngFor="let item of items"
        tabindex="0"
      >
        <div
          style="display: flex"
          (click)="clickActions(item.name)"
        >
          <app-svg-renderer
            [icon]="item.icon"
            [width]="12"
            [height]="12"
            class="avatarIcons"
          />
          <span class="menu-text">{{ item.name }}</span>
        </div>
      </li>
    </ul>
  </div>
</div>
