import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  public setLoader: boolean = false;
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnChanges() {
    if (this.setLoader) {
      this.document.body.classList.add('no-scroll');
    } else {
      this.document.body.classList.remove('no-scroll');
    }
  }

  toggleLoaderVisibility() {
    this.setLoader = !this.setLoader;
  }
}
