<app-confirmation-dialog
  [type]="'Delete'"
  [confirmationText]="'Are you sure you want to delete this record?'"
  [hintText]="
    'Deleting this record will be permanent and cannot be restored again.'
  "
  (submitDialog)="deleteDistrict()"
></app-confirmation-dialog>
<div class="gridContainer">
  <app-grid-template
    pageName="Tracking"
    [gridData]="gridData"
    [gridSchema]="gridSchema"
    [excelExportFunction]="getExcelData"
    (triggerExportToPDF)="onExportToPDF($event)"
    (triggerGetGridDataAPI)="getGridData($event)"
    (triggerActionEvent)="handleActionEvent($event)"
  ></app-grid-template>
</div>
