import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { AppComponent } from '../../../app.component';
import {
  GridApiParamsData,
  GridDataSchema,
  GridTriggeredActionData,
} from '../../../interfaces/shared/grid-data/grid-data.interface';
import { DistrictsListGridSchema } from '../../../schemas/customers/districts-list.schema';
import { GridDataService } from '../../../services/shared/grid-data.service';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrackingComponent {
  public gridSchema: GridDataSchema = DistrictsListGridSchema;
  public gridData: GridDataResult = { data: [], total: 0 };
  public rowsToExport: any[];

  private state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: 'or' },
    sort: [],
  };

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent;
  @ViewChild(ConfirmationDialogComponent)
  confirmationDialogComponent!: ConfirmationDialogComponent;

  constructor(
    private _appComponent: AppComponent,
    private _authenticationService: AuthenticationService,
    private _gridDataService: GridDataService,
  ) {
    this.getExcelData = this.getExcelData.bind(this);
  }

  ngAfterViewInit() {
    this.getGridData();
  }

  public getGridData(apiParams?: GridApiParamsData): void {
    this._appComponent.loaderComponent.toggleLoaderVisibility();
    let filter = {
      skip: this.state.skip,
      take: this.state.take,
      group: this.state.group,
      filter: this.state.filter,
      sort: this.state.sort,
    };

    if (apiParams != null || apiParams != undefined) {
      $.extend(filter, apiParams.pageState);
      $.extend(filter, apiParams);
    }

    this._gridDataService
      .getGridDataByCustomFilter(filter, 'rides/get')
      .subscribe({
        next: (response: any) => {
          this.gridData = response;

          const tableCells = document.querySelectorAll('.k-grid-column-menu');

          tableCells.forEach(cell => {
            const titleAttribute = cell.getAttribute('title');

            // Check if the 'title' attribute exists and contains 'Column Menu'
            if (titleAttribute && titleAttribute.includes('Column Menu')) {
              // Get the index where 'Column Menu' starts
              const indexOfColumnMenu = titleAttribute.indexOf('Column Menu');

              const newTitleContent = titleAttribute
                .slice(0, indexOfColumnMenu)
                .trim();

              // Update the 'title' attribute with the new content
              cell.setAttribute('title', newTitleContent);
            }
          });
        },
        error: error => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
        complete: () => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
      });
  }

  public getExcelData = (
    selectedRows: string[],
    data: any[],
  ): ExcelExportData => {
    const selectedRecords =
      selectedRows.length > 0
        ? data.filter((item: any) => selectedRows.includes(item.id))
        : data;
    return {
      data: selectedRecords,
    };
  };

  public onExportToPDF(selectedRows: string[]): void {
    if (selectedRows.length > 0) {
      this.rowsToExport = this.gridData.data.filter(item =>
        selectedRows.includes(item.id),
      );
    } else {
      this.rowsToExport = this.gridData.data;
    }

    this._appComponent.loaderComponent.toggleLoaderVisibility();
    setTimeout(() => {
      this._appComponent.loaderComponent.toggleLoaderVisibility();
      this.hiddenGrid.saveAsPDF();
    }, 500);
  }

  public handleActionEvent(triggeredData: GridTriggeredActionData): void {
    const { actionType, detectedItemData } = triggeredData;

    if (actionType === 'delete') {
      this.openDeleteDialog(detectedItemData.id);
    }
  }

  private openDeleteDialog(id: number): void {
    this.confirmationDialogComponent.open();
  }

  public deleteDistrict(): void {
    this._appComponent.loaderComponent.toggleLoaderVisibility();
  }
}
