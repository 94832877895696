// Angular Core Modules
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

// Third-party Libraries
import { ChartsModule } from '@progress/kendo-angular-charts';
import { AngularSvgIconModule } from 'angular-svg-icon';

// Modules and Components
import { HomeComponent } from 'src/app/pages/home/home.component';
import { ComponentsModule } from 'src/app/shared/components/components.module';

// Services and Guards
import { AuthGuard } from 'src/app/auth/helpers';
import { AuthorizationGuard } from 'src/app/auth/helpers/authorization.guard';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

// Enums and Pipes
import { GoogleMapsModule } from '@angular/google-maps';
import { PermissionsList } from 'src/app/enums/Permissions/PermissionList';
import { PluralizePipe } from 'src/app/pipes/shared/pluralize.pipe';
import { AppComponentsModule } from '../../components/components.module';
import { GridTemplateComponent } from '../../components/shared/app-grid-template/app-grid-template.component';
import { AreaChartComponent } from '../../pages/telematics/area-chart/area-chart.component';
import { BarStackNormalizationChartComponent } from '../../pages/telematics/bar-stack-normalization-chart/bar-stack-normalization-chart.component';
import { LineChartComponent } from '../../pages/telematics/line-chart/line-chart.component';
import { OverviewComponent } from '../../pages/telematics/overview/overview.component';
import { PieRoseTypeChartComponent } from '../../pages/telematics/pie-roseType-chart/pie-roseType-chart.component';
import { TelematicsMapComponent } from '../../pages/telematics/telematics-map/telematics-map.component';
import { TelematicsSectionDetailsComponent } from '../../pages/telematics/telematics-section-details/telematics-section-details.component';
import { TelematicsSectionMapComponent } from '../../pages/telematics/telematics-section-map/telematics-section-map.component';
import { TrackingComponent } from '../../pages/telematics/tracking/tracking.component';
import { DriversService } from '../../services/drivers/drivers-list.service';
import { RideNavigationService } from '../../services/rides/ride-navigation.service';
import { RidesService } from '../../services/rides/rides-list.service';
import { GridDataService } from '../../services/shared/grid-data.service';
import { DatePipe } from '@angular/common';

const routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {
      animation: 'home',
      PermissionPageName: [
        PermissionsList[
          (PermissionsList.OverviewView,
          PermissionsList.TrackingView,
          PermissionsList.TrackingView,
          PermissionsList.TelematicsView)
        ],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: 'overview',
    component: OverviewComponent,
    data: {
      animation: 'overview',
      PermissionPageName: [PermissionsList[PermissionsList.OverviewView]],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: 'tracking',
    component: TrackingComponent,
    data: {
      animation: 'tracking',
      PermissionPageName: [PermissionsList[PermissionsList.TrackingView]],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: 'telematicsmap',
    component: TelematicsMapComponent,
    data: {
      animation: 'telematicsmap',
      PermissionPageName: [PermissionsList[PermissionsList.TelematicsView]],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  declarations: [
    HomeComponent,
    OverviewComponent,
    TrackingComponent,
    TelematicsMapComponent,
    PluralizePipe,
    LineChartComponent,
    BarStackNormalizationChartComponent,
    PieRoseTypeChartComponent,
    AreaChartComponent,
    TelematicsSectionMapComponent,
    TelematicsSectionDetailsComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    BrowserModule,
    ComponentsModule,
    AppComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    ChartsModule,
    GridTemplateComponent,
    GoogleMapsModule,
  ],
  exports: [
    HomeComponent,
    OverviewComponent,
    TrackingComponent,
    LineChartComponent,
    TelematicsMapComponent,
    BarStackNormalizationChartComponent,
    PieRoseTypeChartComponent,
    AreaChartComponent,
    TelematicsSectionMapComponent,
    TelematicsSectionDetailsComponent,
  ],
  providers: [
    DashboardService,
    GridDataService,
    DriversService,
    RidesService,
    RideNavigationService,
    DatePipe,
  ],
})
export class DashboardModule {}
