import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-renderer',
  templateUrl: './svg-renderer.component.html',
})
export class SvgRendererComponent {
  @Input() icon!: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() class?: string;
}
