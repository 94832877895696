import { DOCUMENT, LocationStrategy } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SideBarComponent } from '../../main-layout/Components/side-bar/side-bar.component';
import { HeaderComponent } from '../Components/header/header.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent {
  isFullStyle: boolean = true;
  title = 'CE-Dispatcher';
  @ViewChild('sidebar') sidebar: SideBarComponent | undefined;
  @ViewChild(HeaderComponent) HeaderComponent!: HeaderComponent;
  currentUser: boolean;
  isSidebarExpanded = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _authenticationService: AuthenticationService,
    private url: LocationStrategy,
  ) {
    if (this._authenticationService.getParamValueQueryString('api_key')) {
      this.isFullStyle = false;
    } else {
      this.isFullStyle = true;
      this.currentUser = _authenticationService.currentUserValue != null;
      if (
        this.url.path().includes('login') &&
        this.currentUser &&
        Object.keys(this._authenticationService.currentUserValue).length === 0
      ) {
        this.IsSideMenuActive = false;
      }
    }
  }

  IsSideMenuActive: boolean = true;
  onSidebarToggled() {
    if (this._authenticationService.getParamValueQueryString('api_key')) {
      this.isFullStyle = false;
    } else {
      this.isFullStyle = true;
      if (this.sidebar) {
        if (this.isSidebarExpanded) {
          this.sidebar.collapse();
        } else {
          this.sidebar.expand();
        }
        this.isSidebarExpanded = !this.isSidebarExpanded;
      }
    }
  }

  changeOfRoutes() {
    if (this._authenticationService.getParamValueQueryString('api_key')) {
      this.isFullStyle = false;
    } else {
      this.isFullStyle = true;
      this.currentUser = this._authenticationService.currentUserValue != null;
      window.addEventListener('locationchange', function () {});
      window.dispatchEvent(new Event('locationchange'));
      if (this.url.path().includes('login')) {
        this.IsSideMenuActive = false;
      } else {
        this.IsSideMenuActive = true;
      }
    }
  }
}
