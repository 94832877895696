import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { PermissionsList } from 'src/app/enums/Permissions/PermissionList';
import { User } from 'src/app/interfaces/authentication/user.interface';
import { CoreMenu } from 'src/app/interfaces/core-menu-data/core-menu-data.interface';
import { MenuService } from 'src/app/menu/menu';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  public submitted = false;
  public passwordTextType: boolean;
  public loginForm: FormGroup;

  constructor(
    private _authService: AuthenticationService,
    private router: Router,
    private _menuService: MenuService,
    private _appcomponent: AppComponent,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
  ) {}

  ngOnInit() {
    let that = this;
    that.loginForm = that._formBuilder.group({
      Username: ['', [Validators.required]],
      Password: ['', Validators.required],
    });
  }

  onSubmit(event: Event) {
    let that = this;
    event.preventDefault();
    if (this.loginForm.invalid) {
      that.submitted = true;
      return;
    }
    if (!localStorage.getItem('User_Data')) {
      this._appcomponent.loaderComponent.toggleLoaderVisibility();
      this._authService.userData = JSON.parse(
        localStorage.getItem('User_Data')!,
      );
      this._authService.login(that.loginForm.value).subscribe({
        next: response => {
          this._appcomponent.loaderComponent.toggleLoaderVisibility();
          let currentUser = {} as User;

          if (response.data.apiKey && response.data.apiKey != '') {
            this._authService.clearCurrentUser();
          } else {
            currentUser.email = response.data.email;
            currentUser.fullName = response.data.fullName;
            currentUser.id = response.data.id;
            currentUser.permissionList = response.data.permissions;
            currentUser.token = response.data.accessToken;
            currentUser.refreshToken = response.data.refreshToken;
            currentUser.idToken = response.data.idToken;
            this._authService.saveUserData(currentUser);
            this._menuService.fillMenuObject();
          }
          this._authService.onGetGoogleAPIKey.emit(true);

          let mainMenuResult = this._authService.getDefaultPage(
            this._menuService.MainMenu,
          );
          let systemMenuResult = this._authService.getDefaultPage(
            this._menuService.SystemMenu,
          );

          if (mainMenuResult == '/' && systemMenuResult == '/') {
            this._authService.logout();
          } else if (mainMenuResult != '/')
            this.router.navigate([`/${mainMenuResult}`]);
          else if (systemMenuResult != '/')
            this.router.navigate([`/${systemMenuResult}`]);
        },
        error: (err: any) => {
          this._appcomponent.loaderComponent.toggleLoaderVisibility();
          let messageError = err.error ? err.error.message : err;
          if (messageError != '' && messageError != undefined) {
            that._toastrService.error(messageError);
          } else {
            this._toastrService.error(
              'Technical issue detected. Please contact the administration',
            );
          }
        },
        complete: () => {},
      });
    }
  }

  reflectHeaderMenu(test: any, url: string) {
    if (url == '') {
      return;
    } else if (url == window.location.pathname.substring(1)) {
      return;
    } else {
      sessionStorage.setItem('currentMenu', JSON.stringify(test));
      sessionStorage.setItem('routerLinkActive', '/' + url);
      window.dispatchEvent(new Event('changeMenu'));
    }
  }

  getDefaultCoreMenu(array: CoreMenu[]): any {
    let userinfo = JSON.parse(localStorage.getItem('currentUser')!) as User;
    if (userinfo != null) {
      return this.CheckTopMenu(array, 0, userinfo);
    }
  }

  CheckTopMenu(array: any, i: number, userinfo: any) {
    if (array[i].children.length)
      array[i].children.forEach((item: any, j: any) => {
        return this.CheckChildrenMenu(array, i, j, userinfo);
      });
    else if (
      userinfo.PermissionList.includes(
        PermissionsList[array[i].permission as keyof typeof PermissionsList],
      )
    )
      return array[i];
  }

  CheckChildrenMenu(array: any, i: number, j: number, userinfo: any) {
    if (array[i].children![j].permission) {
      if (
        userinfo.PermissionList.includes(
          PermissionsList[
            array[i].children![j].permission as keyof typeof PermissionsList
          ],
        )
      ) {
        return array[i];
      }
    } else {
      this.CheckChildOfChildrenMenu(array, i, j, userinfo);
    }
  }

  CheckChildOfChildrenMenu(array: any, i: number, j: number, userinfo: any) {
    array[i].children![j].children.forEach((item: any, c: any) => {
      if (
        userinfo.PermissionList.includes(
          PermissionsList[
            array[i].children![j].children![c]
              .permission as keyof typeof PermissionsList
          ],
        )
      ) {
        return array[i];
      }
    });
  }
}
