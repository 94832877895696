import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { PermissionsList } from '../enums/Permissions/PermissionList';
import { CoreMenu } from '../interfaces/core-menu-data/core-menu-data.interface';

@Injectable({ providedIn: 'root' })
export class MenuService {
  public MainMenu: CoreMenu[] = [];
  public SystemMenu: CoreMenu[] = [];
  constructor(public _authenticationService: AuthenticationService) {
    this.fillMenuObject();
  }
  fillMenuObject() {
    this.MainMenu = [
      {
        id: 'home',
        title: 'Home',
        url: 'home',
        icon: 'home',
        permission: PermissionsList[PermissionsList.OverviewView],
        hidden: !this._authenticationService.checkParantAccess([
          PermissionsList[PermissionsList.OverviewView],
        ]),
      },
    ];

    this.SystemMenu = [];
  }
}
