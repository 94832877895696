<div>
  <div class="pageHeader mt-4 mb-2 d-flex">
    <div class="map-title">Details</div>
    <div class="date-badge">
      <div>
        {{
          rideDetailsResult
            ? this.datepipe.transform(
                rideDetailsResult.dateOfService,
                "MMM dd, yyyy"
              )
            : ("" | date: "MMM dd, yyyy")
        }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <h5 class="card-title ride-title">Ride Details</h5>
      <div class="ride-card">
        <div class="ride-card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="row info-section">
                <div class="col-lg-4 info-card">
                  <img
                    alt="driver-name"
                    src="assets/images/icons/icon-driver-name.png"
                  />
                  <div class="information">
                    <div class="title">DRIVER NAME</div>
                    <div class="value">
                      {{ rideDetailsResult ? rideDetailsResult.fullName : "" }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 info-card">
                  <img
                    alt="driver"
                    src="assets/images/icons/icon-driver-id.png"
                  />
                  <div class="information">
                    <div class="title">DRIVER ID</div>
                    <div class="value">
                      {{ rideDetailsResult ? rideDetailsResult.driverId : "" }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 info-card">
                  <img
                    alt="driver-name"
                    src="assets/images/icons/Vector.png"
                  />
                  <div class="information">
                    <div class="title">RIDE ID</div>
                    <div class="value">
                      {{ rideDetailsResult ? rideDetailsResult.rideId : "" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row info-section">
                <div class="col-lg-4 info-card">
                  <img
                    alt="ride-status"
                    src="assets/images/icons/icon-ride-status.png"
                  />
                  <div class="information">
                    <div class="title">RIDE STATUS</div>
                    <div class="value">
                      {{
                        rideDetailsResult ? rideDetailsResult.lookupName : ""
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 info-card">
                  <img
                    alt="distance"
                    src="assets/images/icons/icon-distance.png"
                  />
                  <div class="information">
                    <div class="title">DISTANCE</div>
                    <div class="value">
                      {{ rideDetailsResult ? rideDetailsResult.distance : "" }}
                      mi
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 info-card">
                  <img
                    alt="driver-name"
                    src="assets/images/icons/duration.png"
                  />
                  <div class="information">
                    <div class="title">DURATION</div>
                    <div class="value">
                      {{ rideDetailsResult ? rideDetailsResult.duration : "" }}
                      min
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <h5 class="card-title ride-title">Ride Events</h5>
      <div class="ride-card">
        <div class="ride-card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="row info-section">
                <div class="col-lg-4 info-card">
                  <img
                    alt="phone-used"
                    src="assets/images/icons/icon-phone-used.png"
                  />
                  <div class="information">
                    <div class="title">PHONE USAGE</div>
                    <div class="value">{{ getRideEventValue(1) }}%</div>
                  </div>
                </div>
                <div class="col-lg-4 info-card">
                  <img
                    alt="hard-braking"
                    src="assets/images/icons/icon-hard-braking.png"
                  />
                  <div class="information">
                    <div class="title">HARD BRAKING</div>
                    <div class="value">{{ getRideEventValue(4) }}%</div>
                  </div>
                </div>
                <div class="col-lg-4 info-card">
                  <img
                    alt="hard-return"
                    src="assets/images/icons/icon-hard-return.png"
                  />
                  <div class="information">
                    <div class="title">HARD RETURNS</div>
                    <div class="value">{{ getRideEventValue(5) }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row info-section">
                <div class="col-lg-4 info-card">
                  <img
                    alt="speed-limit"
                    src="assets/images/icons/icon-speed-limit.png"
                  />
                  <div class="information">
                    <div class="title">SPEED</div>
                    <div class="value">{{ getRideEventValue(3) }}%</div>
                  </div>
                </div>
                <div class="col-lg-4 info-card">
                  <img
                    alt="acceleration"
                    src="assets/images/icons/icon-acceleration.png"
                  />
                  <div class="information">
                    <div class="title">ACCELERATION</div>
                    <div class="value">{{ getRideEventValue(2) }}%</div>
                  </div>
                </div>
                <div class="col-lg-4 info-card">
                  <img
                    alt="percentage"
                    src="assets/images/icons/icon-percentage.png"
                  />
                  <div class="information">
                    <div class="title">PERCENTAGE</div>
                    <div class="value">
                      {{
                        rideDetailsResult ? rideDetailsResult.percentage : ""
                      }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <h5 class="card-title ride-title">Driver Statistics</h5>
      <div class="row">
        <div class="col-lg-12">
          <div class="table-view-grid">
            <div class="table-view-row">
              <div class="table-view-cell">TOTAL RIDES</div>
              <div class="table-view-cell">
                {{
                  rideDetailsResult && rideDetailsResult.driverStatistic
                    ? rideDetailsResult.driverStatistic.totalRide
                    : ""
                }}
              </div>
            </div>
            <div class="table-view-row">
              <div class="table-view-cell">DISTANCE</div>
              <div class="table-view-cell">
                {{
                  rideDetailsResult && rideDetailsResult.driverStatistic
                    ? rideDetailsResult.driverStatistic.totalDistance
                    : ""
                }}
                mi
              </div>
            </div>
            <div class="table-view-row">
              <div class="table-view-cell">DURATION</div>
              <div class="table-view-cell">
                {{
                  rideDetailsResult && rideDetailsResult.driverStatistic
                    ? splitTime(rideDetailsResult.driverStatistic.totalDuration)
                    : ""
                }}min
              </div>
            </div>
            <div class="table-view-row">
              <div class="table-view-cell">PHONE USAGE</div>
              <div class="table-view-cell">
                {{
                  rideDetailsResult && rideDetailsResult.driverStatistic
                    ? rideDetailsResult.driverStatistic.totalPhoneUsed
                    : ""
                }}
              </div>
            </div>
            <div class="table-view-row">
              <div class="table-view-cell">HARD BRAKING</div>
              <div class="table-view-cell">
                {{
                  rideDetailsResult && rideDetailsResult.driverStatistic
                    ? rideDetailsResult.driverStatistic.totalHardBraking
                    : ""
                }}
              </div>
            </div>
            <div class="table-view-row">
              <div class="table-view-cell">HARD RETURN</div>
              <div class="table-view-cell">
                {{
                  rideDetailsResult && rideDetailsResult.driverStatistic
                    ? rideDetailsResult.driverStatistic.totalHardReturn
                    : ""
                }}
              </div>
            </div>
            <div class="table-view-row">
              <div class="table-view-cell">SPEED</div>
              <div class="table-view-cell">
                {{
                  rideDetailsResult && rideDetailsResult.driverStatistic
                    ? rideDetailsResult.driverStatistic.totalSpeed
                    : ""
                }}
              </div>
            </div>
            <div class="table-view-row">
              <div class="table-view-cell">ACCELERATION</div>
              <div class="table-view-cell">
                {{
                  rideDetailsResult && rideDetailsResult.driverStatistic
                    ? rideDetailsResult.driverStatistic.totalAcceleration
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
