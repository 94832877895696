import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MainLayoutComponentsModule } from './Components/mainLayoutComponents.module';
import { MainLayoutComponent } from './layout/main-layout.component';

@NgModule({
  declarations: [MainLayoutComponent],
  exports: [MainLayoutComponent, MainLayoutComponentsModule],
  imports: [
    BrowserModule,
    RouterModule,
    ComponentsModule,
    MainLayoutComponentsModule,
  ],
})
export class MainlayoutModule {}
