import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { CoreMenuItem } from 'src/app/interfaces/core-menu-data/core-menu-data.interface';

@Component({
  selector: 'app-side-bar-menu',
  templateUrl: './side-bar-menu.component.html',
  styleUrls: ['./side-bar-menu.component.css'],
})
export class SideBarMenuComponent {
  @Input() items: CoreMenuItem[] = [];

  constructor(private router: Router) {}

  toggleSubMenu(item: CoreMenuItem) {
    const count = (item.children ?? []).length;

    if (count > 0) {
      item.openTab = !item.openTab;
    }
  }

  gotoPage(item: CoreMenuItem) {
    this.router.navigate([`/${item.url}`]);
  }
}
