export interface RidesListData {
  data: RideList[];
}
export interface RideList {
  id?: number;
  fullName?: string;
  accountId?: number;
}
export class Ride {
  createdByAccountId: number;
  createdByName: string;
  dateOfService: Date;
  distance: number;
  driverId: bigint;
  duration: number;
  estimatedDistanceInMiles: number;
  events: Array<number>;
  id: bigint;
  isDeleted: boolean;
  percentage: number;
  rideId: bigint;
}

export class RideDetail {
  id: bigint;
  rideId: bigint;
  driverId: bigint;
  fullName: string;
  dateOfService: Date;
  estimatedDistanceInMiles: number;
  duration: number;
  percentage: number;
  distance: number;
  lookupName: string;
  rideEvents: Array<RideEvent> = new Array<RideEvent>();
  driverStatistic: DriverStatistic = new DriverStatistic();
}

export class RideEvent {
  name: string;
  eventTypeId: number;
  percentage: number;
}
export class DriverStatistic {
  totalRide: number = 0;
  totalDistance: number = 0;
  totalDuration: number = 0;
  totalPhoneUsed: number = 0;
  totalHardBraking: number = 0;
  totalHardReturn: number = 0;
  totalSpeed: number = 0;
  totalAcceleration: number = 0;
}

export interface RideDetailFilterDto {
  rideId: bigint;
  driverId: bigint;
  dateOfService?: Date;
}

export class CoordinateDto {
  lat: number;
  lng: number;
}
export class RideEventDetailCoordinateDto implements CoordinateDto {
  rideId: bigint;
  eventTypeId: bigint;
  time: Date;
  lat: number;
  lng: number;
}
export class BasicRideCoordinateDto {
  startCoordinate: CoordinateDto;
  endCoordinate: CoordinateDto;
  coordinates: Array<CoordinateDto>;
  rideEventDetailCoordinates: Array<RideEventDetailCoordinateDto>;
}
