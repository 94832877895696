import { Component, ElementRef, HostListener } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

export interface MenuItem {
  name: string;
  text: string;
  icon?: string;
  subItems?: MenuItem[];
}

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
})
export class AvatarComponent {
  public img1 =
    'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg';

  items = [
    { name: 'Profile', icon: 'profile' },
    { name: 'Settings', icon: 'setting' },
    { name: 'Logout', icon: 'logout' },
  ];

  constructor(
    private elRef: ElementRef,
    private _authenticationService: AuthenticationService,
  ) {}

  showMenu = false;

  onToggleMenu() {
    this.showMenu = !this.showMenu;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }
  clickActions(name: string) {
    if (name == 'Logout') {
      this._authenticationService.logout();
    }
  }
}
