import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { DialogsModule } from '@progress/kendo-angular-dialog';

import { LoginComponent } from './login/login.component';

// routing
const routes: Routes = [
  {
    path: 'authentication/login',
    component: LoginComponent,
    data: { animation: 'auth' },
  },
  {
    path: 'authentication/login/callback',
    component: LoginComponent,
    data: { animation: 'auth' },
  },
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    DialogsModule,
  ],
})
export class AuthenticationModule {}
