<div
  class="mainContainer"
  *ngIf="setLoader"
>
  <div class="mask"></div>
  <div class="wrapper">
    <kendo-loader
      type="pulsing"
      themeColor="primary"
      size="large"
    >
    </kendo-loader>
    <span class="loadingText">Loading</span>
  </div>
</div>
