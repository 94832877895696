import { GridDataSchema } from 'src/app/interfaces/shared/grid-data/grid-data.interface';

export const DistrictsListGridSchema: GridDataSchema = {
  cols: [
    {
      key: 'driverId',
      displayName: 'Driver ID',
      isSortable: true,
      isFilterable: true,
      width: 110,
    },
    {
      key: 'rideId',
      displayName: 'Ride ID',
      isSortable: true,
      isFilterable: true,
      width: 105,
    },
    {
      key: 'dateOfService',
      displayName: 'Date of Service',
      isSortable: true,
      isFilterable: true,
      width: 200,
      format: 'MMM dd, yyyy',
    },
    {
      key: 'calculatedDuration',
      displayName: 'Duration',
      isSortable: true,
      isFilterable: true,
      width: 150,
      valueIcon: ' minutes',
    },
    {
      key: 'distance',
      displayName: 'Distance',
      isSortable: true,
      isFilterable: true,
      width: 150,
      valueIcon: ' miles',
    },
    {
      key: 'percentage',
      displayName: 'Percentage',
      isSortable: true,
      isFilterable: true,
      width: 150,
      valueIcon: '%',
    },
    {
      key: 'events',
      displayName: 'Events',
      isSortable: false,
      isFilterable: false,
      hasTelematicEventCell: true,
      width: 150,
    },
    {
      key: 'rideId',
      displayName: 'Telematics',
      isSortable: false,
      isFilterable: false,
      hasTelematicCell: true,
      width: 100,
    },
  ],
};
