<!--
  "active" class enables selective pre-rendering based on "shouldPreRender" and "isActive"
  conditions. Provides controlled DOM visibility via CSS styles. If "shouldPreRender" is false,
  the component remains hidden from the DOM until "isActive" is true.
-->
<div
  *ngIf="shouldPreRender || isActive"
  [class.pre-rendered-tab]="shouldPreRender"
  [class.active]="shouldPreRender && isActive"
>
  <ng-content></ng-content>
</div>
