import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient) {}

  public getDashboardCharts(locationId: number): Observable<any> {
    return this.http.post(
      `${environment.mainapiUrl}/Charts/GetDashboardCharts/${locationId}`,
      { locationId: locationId },
    );
  }

  public getDashboardStatistic(locationId: number): Observable<any> {
    return this.http.post(
      `${environment.mainapiUrl}/Charts/GetDashboardStatistic/${locationId}`,
      { locationId: locationId },
    );
  }
}
