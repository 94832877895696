<ng-template
  #template
  left-anchor
>
  <img
    alt="image"
    style="width: 100px; height: 50px; object-fit: contain"
    src="{{ imageUrl }}"
  />
</ng-template>

<div
  kendoTooltip
  showOn="none"
  [tooltipTemplate]="template"
  style="width: fit-content"
  filter=".k-grid td"
  (mouseenter)="showToolTip($event)"
  (mouseleave)="hideToolTip($event)"
  class="tooltipGridLeft"
>
  <svg-icon
    class="svghint"
    src="assets/images/icons/eye.svg"
  ></svg-icon>
</div>
