<div style="justify-content: center; display: flex">
  <div *ngIf="events?.length == 0">N/A</div>
  <button
    *ngFor="let item of events"
    id="event"
    style="
      border: 0.45px;
      border-color: transparent;
      background-color: transparent;
    "
    disabled
  >
    <img
      alt="name"
      src="assets/images/icons/{{ setStatusIcon(item.id) }}.png"
      title="{{ item.name }}"
    />
  </button>
</div>
