import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
  ) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let api_key =
      this._authenticationService.getParamValueQueryString('api_key');
    if (api_key && api_key != '') {
      this._authenticationService.clearCurrentUser();
      return true;
    }

    const currentUser = this._authenticationService.currentUserValue;
    if (
      currentUser &&
      Object.keys(this._authenticationService.currentUserValue).length > 0
    ) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login']);
    return false;
  }
}
