import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ButtonLoaderComponent {
  @ViewChild('button') button: ElementRef;

  @Input() class: string;
  @Input() sizeClass: string;
  @Input() buttonWidth: string;
  @Input() disabled: boolean;

  @Output() onClick = new EventEmitter<void>();

  handleClick() {
    this.button.nativeElement.blur();
    this.onClick.emit();
  }
}
