import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { TabComponent } from './app-tabs/app-tab/app-tab.component';
import { TabsComponent } from './app-tabs/app-tabs.component';

@NgModule({
  declarations: [TabsComponent, TabComponent],
  imports: [CommonModule, Ng2FlatpickrModule],
  exports: [TabsComponent, TabComponent],
})
export class AppComponentsModule {}
