<kendo-dialog
  *ngIf="opened"
  (close)="close()"
  class="about-dialog"
>
  <kendo-dialog-titlebar> </kendo-dialog-titlebar>
  <div
    style="font-size: 16.2px; line-height: 1.17em"
    class="icon-container"
  >
    <div class="icon-wrapper">
      <svg-icon
        src="assets/images/icons/aboutIcon.svg"
        [svgStyle]="{ 'width.px': 21.6, 'height.px': 21.6 }"
        class="delete-icon"
      ></svg-icon>
    </div>
  </div>
  <span class="confirmation-text"> About </span>
  <div class="company-info">
    <span class="company-title"> ADROIT </span>
    <span class="companny-version"> Version 1.4.0 </span>
  </div>
  <div class="description">
    <span class="hint-text">
      For any support-related questions, please reach out to us at:
    </span>
    <a
      href="https://www.goadroit.com/terms-of-service"
      class="support-link"
    >
      cesupport@gobeacon.com
    </a>
  </div>
  <div class="rights-container">
    <span class="right-text">
      ©2023 Adroit Advanced Technologies Inc, a Beacon company. All rights
      reserved.
    </span>
  </div>
  <div class="buttons-container">
    <app-button
      class="primary"
      sizeClass="sm"
      [ngStyle]="{ 'width.px': 111.6 }"
      (click)="close()"
      >Ok</app-button
    >
  </div>
</kendo-dialog>
