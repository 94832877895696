<kendo-dropdownlist
  class="grid-filter-ddl"
  [popupSettings]="{
    popupClass: ddlFilterpopup.toString()
  }"
  [value]="FilterValus.operator"
  [(ngModel)]="FilterValus.operator"
  placeholder="select"
  [data]="filterData"
>
  <ng-template
    kendoDropDownListValueTemplate
    let-dataItem
  >
    {{ dataItem == "startswith" ? "Starts with" : "Contains" }}
  </ng-template>
  <ng-template
    kendoDropDownListItemTemplate
    let-dataItem
  >
    {{ dataItem == "startswith" ? "Starts with" : "Contains" }}
  </ng-template>
</kendo-dropdownlist>
<input
  type="text"
  [(ngModel)]="FilterValus.value"
  class="search-input grid-filter-text"
  placeholder="Search"
/>
<div class="row">
  <div class="col-12">
    <input
      type="button"
      class="clear-filter-button k-button k-button-solid-base k-button-solid k-button-md k-rounded-md k-button-rectangle"
      value="Clear"
      (click)="ClearFilter()"
    />
    <input
      type="button"
      class="filter-button k-button k-button-solid-primary k-button-solid k-button-md k-rounded-md k-button-rectangle"
      (click)="ApplycolumnFilter()"
      value="Filter"
    />
  </div>
</div>
