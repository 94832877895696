<div class="my-google-map">
  <div
    class="map-container"
    *ngIf="showData"
  >
    <google-map
      #map
      [options]="options"
    >
      <map-polyline
        [path]="basicRideCoordinateDto.coordinates"
        strokeWeight="5"
        strokeColor="#2e6fa6"
      ></map-polyline>
      <map-marker
        #markerdata="mapMarker"
        *ngFor="let mark of markers"
        [position]="mark.position"
        [icon]="mark.icon"
        (mapClick)="openInfoWindow(markerdata, mapinfowindow, mark)"
      ></map-marker>
      <map-info-window #mapinfowindow="mapInfoWindow"></map-info-window>
    </google-map>
  </div>
</div>
