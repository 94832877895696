import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-main-context',
  templateUrl: './app-main-context.component.html',
  styleUrls: ['./app-main-context.component.css'],
})
export class AppMainContextComponent {
  private toastRefs: ToastRef<any>[] = [];

  constructor(
    @Inject(DOCUMENT) public document: any,
    private toastr: ToastrService,
    public _authenticationService: AuthenticationService,
  ) {}
}
