import { Component, ViewEncapsulation } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CoreMenu } from 'src/app/interfaces/core-menu-data/core-menu-data.interface';
import { MenuService } from 'src/app/menu/menu';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SidebarService } from 'src/app/services/header/sidebar.service';
interface AppTool {
  id: number;
  publicUrl: string;
  name: string;
  smallImg: string;
  bigImg: string;
  Urls: any[];
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent {
  width: string = '57.6px';
  isSidebarExpanded: boolean = false;
  public MainMenu: CoreMenu[] = [];
  public SystemMenu: CoreMenu[] = [];
  public ShowSystemLabel: boolean = false;

  constructor(
    private _menuService: MenuService,
    private _sidebarService: SidebarService,
    private _appComponent: AppComponent,
    private _authenticationService: AuthenticationService,
  ) {
    this.MainMenu = _menuService.MainMenu.filter(x => !x.hidden);
    this.SystemMenu = _menuService.SystemMenu.filter(x => !x.hidden);
    this.SystemMenu.forEach(element => {
      if (element.children && element.children.length > 0) {
        element.children = element.children.filter(x => !x.hidden);

        element.children.forEach(child => {
          if (child.children && child.children.length > 0)
            child.children = child.children.filter(x => x.hidden);
        });
      }
    });
    let count = this.SystemMenu;
    this.ShowSystemLabel = count.length > 0;
  }

  appTools: AppTool[] = [];

  openOverlay(menuLength: any) {
    let length = parseInt(menuLength);
    if (length > 0) {
      this.isSidebarExpanded = true;
    }
  }

  closeOverlay() {
    this.isSidebarExpanded = false;
  }

  toggleSidebar(): void {
    if (this.width === '57.6px') {
      this.expand();
    } else {
      this.collapse();
    }
  }

  expand(): void {
    this.width = '198px';
  }

  collapse(): void {
    this.width = '57.6px';
  }

  reflectHeaderMenu(menu: any, url: string) {
    const currentPath = window.location.pathname.substring(1);

    if (url === '' || url === currentPath) {
      return;
    }

    sessionStorage.setItem('currentMenu', JSON.stringify(menu));
    window.dispatchEvent(new Event('changeMenu'));
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('loadMenuFunctionality'));
  }
}
