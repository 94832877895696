import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { BasicRideCoordinateDto } from '../../../interfaces/ride-data/ride-list.interface';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-telematics-section-map',
  templateUrl: './telematics-section-map.component.html',
  styleUrls: ['./telematics-section-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TelematicsSectionMapComponent {
  @ViewChild('map', { static: false }) googlemap: GoogleMap;
  public showData: boolean = false;
  public basicRideCoordinateDto: BasicRideCoordinateDto;
  public options: google.maps.MapOptions;
  public markers: any;

  constructor(private _authenticationService: AuthenticationService) {}

  public openInfoWindow(marker: MapMarker, mapInfoWindow: any, data: any) {
    let that = this;
    if (mapInfoWindow != undefined) {
      this._authenticationService.getGoogleAPIKey().subscribe(result => {
        let key = result.data.propertyValue;
        let templete: string = 'Name: #Name# </br> Address: #Address#';
        let formatted_address = '';
        let stopName = '';
        this._authenticationService.GetGeoCode(data.position.lat, data.position.lng).subscribe(location => {
          if (location && location.data.results && location.data.results.length > 0) {
            formatted_address = location.data.results[0].formatted_address;
            stopName = location.data.results[0].address_components[4].long_name;
          }
          mapInfoWindow.infoWindow.setContent(
            templete
              .replace(
                '#Name#',
                stopName,
              )
              .replace('#Address#', formatted_address),
          );
          mapInfoWindow.open(marker);

        });
      
      });
    }
  }

}
