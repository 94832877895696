import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
})
export class AboutDialogComponent {
  @Output() submitDialog = new EventEmitter<void>();
  @Input() opened = false;

  public districts = [
    {
      name: 'hammed',
      value: 4,
    },
  ];

  public close(): void {
    this.opened = false;
  }
  public open(): void {
    this.opened = true;
  }
  public submit(): void {
    this.submitDialog.emit();
  }
}
