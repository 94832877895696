import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';

import { TabComponent } from './app-tab/app-tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './app-tabs.component.html',
  styleUrls: ['./app-tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @Input() tabCategoryTextColor: string = '#417eb8';
  @Input() activeTabCategoryBackgroundColor: string = '#f8fafc';
  @Input() contentContainerBackgroundColor: string = '#f8fafc';
  @Input() contentContainerPadding: string = '12px';
  @Input() activeTab: number = 0;
  @Input() isSettingsPage: boolean = false;

  @Output('tabSelectionChange') tabSelectionChange = new EventEmitter();

  private selectedTabIndex: number = -1;

  ngAfterContentInit() {
    this.selectTabByIndex(this.activeTab ?? 0, true);
  }

  selectTabByIndex(tabIndex: number, isOriginal: boolean): void {
    if (this.selectedTabIndex !== tabIndex) {
      this.selectedTabIndex = tabIndex;

      this.tabs.forEach((tab, i) => {
        const isActive = i === tabIndex;
        tab.isActive = isActive;

        if (isActive) {
          this.tabSelectionChange.emit({ tab: tab, isOriginal: isOriginal });
        }
      });
    }
  }
}
