import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideBarComponent } from './side-bar/side-bar.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../../../shared/components/components.module';
import { LayoutComponentsModule } from '../../Components/layoutComponents.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarService } from 'src/app/services/header/sidebar.service';

@NgModule({
  declarations: [HeaderComponent, SideBarComponent],
  exports: [HeaderComponent, SideBarComponent],
  imports: [
    NgbDropdownModule,
    BrowserModule,
    RouterModule,
    ComponentsModule,
    LayoutComponentsModule,
    DropDownsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [SidebarService],
})
export class MainLayoutComponentsModule {}
