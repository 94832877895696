<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>
<button
  (click)="handleClick()"
  #button
  [ngClass]="[class, sizeClass]"
  [ngStyle]="{ width: buttonWidth }"
  class="btn"
  [disabled]="disabled"
>
  <ng-content></ng-content>
  <i
    class="fa fa-circle-o-notch fa-spin"
    style="margin-left: 9px"
  ></i>
</button>
