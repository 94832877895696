import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { State } from '@progress/kendo-data-query';

import { GridDataRequestBody } from 'src/app/interfaces/shared/grid-data/grid-data.interface';
import { environment } from 'src/environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const defaultState: State = {
  skip: 0,
  take: 50,
  sort: [],
  filter: { filters: [], logic: 'or' },
};

@Injectable()
export class GridDataService {
  constructor(private http: HttpClient) {}

  /**
   * Gets grid data for login audit.
   * @param apiEndpoint The dynamic API endpoint.
   * @param pagingState The state for data query.
   * @param searchQuery The search query text.
   * @param locationId The selected location identifier.
   * @returns An observable of the grid data.
   */
  public getGridDataByCustomFilter(
    filter: any,
    apiEndpoint: string,
  ): Observable<any> {
    return this.http
      .post(`${environment.mainapiUrl}/${apiEndpoint}`, filter)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Handle errors here
          console.error('Error in getting the grid data: ', error);
          return throwError(() => new Error(error.message));
        }),
      );
  }

  /**
   * Gets grid data for login audit.
   * @param apiEndpoint The dynamic API endpoint.
   * @param pagingState The state for data query.
   * @param searchQuery The search query text.
   * @param locationId The selected location identifier.
   * @returns An observable of the grid data.
   */
  public getGridData(
    pagingState: State,
    searchQuery: string,
    apiEndpoint: string,
    locationId: number = 0,
    isWithTimezone: boolean = false,
  ): Observable<any> {
    const body: GridDataRequestBody = {
      Skip: pagingState.skip || defaultState.skip!,
      Take: pagingState.take || defaultState.take!,
      Sort: pagingState.sort || defaultState.sort!,
      Filter: pagingState.filter || defaultState.filter!,
      SearchAllText: searchQuery,
      ...(!!locationId ? { LocationId: locationId } : {}),
      ...(isWithTimezone
        ? { timeZoneText: Intl.DateTimeFormat().resolvedOptions().timeZone }
        : {}),
    };

    return this.http
      .post(`${environment.mainapiUrl}/${apiEndpoint}`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Handle errors here
          console.error('Error in getting the grid data: ', error);
          return throwError(() => new Error(error.message));
        }),
      );
  }
}
