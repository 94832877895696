import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Position, TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-textTooltip',
  templateUrl: './textTooltip.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./textTooltip.component.scss'],
})
export class TextTooltipComponent {
  @ViewChild(TooltipDirective) public tooltipDirective: TooltipDirective;

  @Input() text: string;
  @Input() width: number;
  @Input() elementId: string;
  @Input() position: Position = 'top';

  public isTooltipVisible: boolean = false;

  public toggleTooltip(event: MouseEvent): void {
    if (this.isTooltipVisible) {
      this.hideTooltip();
    } else {
      const targetElement = event.target as HTMLElement;

      if (this.shouldShowTooltip()) {
        this.showTooltip(targetElement);
      } else {
        this.hideTooltip();
      }
    }
  }

  private isContentOverflowing(element: HTMLElement): boolean {
    return element.offsetWidth < element.scrollWidth;
  }

  private shouldShowTooltip(): boolean {
    const targetElement = document.querySelector(
      `[data-id="${this.elementId}"]`,
    ) as HTMLElement;

    return targetElement && this.isContentOverflowing(targetElement);
  }

  private showTooltip(targetElement: HTMLElement): void {
    this.tooltipDirective.toggle(targetElement);
    this.isTooltipVisible = true;
  }

  private hideTooltip(): void {
    this.tooltipDirective.hide();
    this.isTooltipVisible = false;
  }
}
