import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AvatarModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MenusModule } from '@progress/kendo-angular-menu';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AvatarComponent } from './avatar/avatar.component';
import { CurrentDateTime } from './currentDateTime/current-date-time';
import { SidBarTabsComponent } from './sid-bar-tabs/sid-bar-tabs.component';
import { LineComponent } from './line/line.component';
import { SideBarMenuComponent } from './side-bar-menu/side-bar-menu.component';
import { HeaderMenusComponent } from './header-menus/header-menus.component';
import { ComponentsModule } from '../../shared/components/components.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AboutDialogComponent } from './aboutDialog/about-dialog.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@NgModule({
  declarations: [
    AvatarComponent,
    CurrentDateTime,
    SidBarTabsComponent,
    LineComponent,
    SideBarMenuComponent,
    HeaderMenusComponent,
    AboutDialogComponent,
  ],
  exports: [
    AvatarComponent,
    CurrentDateTime,
    SidBarTabsComponent,
    LineComponent,
    SideBarMenuComponent,
    HeaderMenusComponent,
    AboutDialogComponent,
  ],
  imports: [
    InputsModule,
    BrowserModule,
    AvatarModule,
    ButtonsModule,
    MenusModule,
    DropDownsModule,
    ComponentsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    DialogsModule,
  ],
})
export class LayoutComponentsModule {}
