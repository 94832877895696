import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ToastrModule } from 'ngx-toastr';
import { SafePipeModule } from 'safe-pipe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, UploadInterceptor } from './app.component';
import { AuthGuard, JwtInterceptor } from './auth/helpers';
import { AppComponentsModule } from './components/components.module';
import { LayoutModule } from './layout/layout.module';
import { MiscellaneousModule } from './main/pages/miscellaneous/miscellaneous.module';
import { PagesModule } from './main/pages/pages.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { TimeAgoPipe } from './shared/pipes/timeAgo.pipe';
import { SharedModule } from './shared/shared.module';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./main/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error', //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true,
    },
    TimeAgoPipe,
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
    }),
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    DashboardModule,
    MiscellaneousModule,
    LayoutModule,
    SharedModule,
    DropDownsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NotificationModule,
    UploadsModule,
    TooltipsModule,
    ButtonsModule,
    IndicatorsModule,
    AppComponentsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    ChartsModule,
    SafePipeModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
