import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { map } from 'rxjs';
import { AppComponent } from '../../../app.component';
import {
  DriversList,
  DriversListData,
} from '../../../interfaces/driver-data/driver-list.interface';
import {
  Ride,
  RideDetail,
  RideList,
  RidesListData,
} from '../../../interfaces/ride-data/ride-list.interface';
import { IForm } from '../../../interfaces/shared/IForm';
import { TelematicsDataFormModel } from '../../../interfaces/telematics-data/telematics-data.class';
import { DriversService } from '../../../services/drivers/drivers-list.service';
import { RidesService } from '../../../services/rides/rides-list.service';
import { TelematicsSectionMapComponent } from '../telematics-section-map/telematics-section-map.component';
import { number } from 'echarts';

@Component({
  selector: 'app-telematics-map',
  templateUrl: './telematics-map.component.html',
  styleUrls: ['./telematics-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TelematicsMapComponent {
  @Input('RideData') rideData: Ride;
  @ViewChild('TelematicsSectionMap')
  telematicsSectionMap!: TelematicsSectionMapComponent;
  defaultMapOptions = {
    //USA
    center: {
      lat: 37.09024,
      lng: -95.712891,
    },
    zoom: 2.84,
    mapTypeId: google.maps.MapTypeId.TERRAIN,
  };
  private currentDate: Date = new Date();
  public formattedSelectedDate?: Date;
  public disabledDates = (date: Date): boolean => {
    return date > this.currentDate;
  };
  public driverId?: bigint;
  public rideId?: bigint;
  public driversList: DriversList[] = [];
  public rideList: RideList[] = [];
  public rideDetailsResult: RideDetail = new RideDetail();
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'startsWith',
  };
  public defaultItem = 'defaultItem';
  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return itemArgs.dataItem.disable;
  }
  public changeColor = false;
  public format: FormatSettings = {
    displayFormat: 'MM/dd/yyyy',
    inputFormat: 'MM/dd/yyyy',
  };
  icons = [
    { typeId: BigInt(1), icon: 'assets/images/icons/map-phone.png' }, //Phone Used
    { typeId: BigInt(2), icon: 'assets/images/icons/map-acceleration.png' }, //Acceleration
    { typeId: BigInt(3), icon: 'assets/images/icons/map-speed-limit.png' }, //Speed Limit
    { typeId: BigInt(4), icon: 'assets/images/icons/map-hard-braking.png' }, //Hard Braking
    { typeId: BigInt(5), icon: 'assets/images/icons/map-hard-return.png' }, //Hard Return
  ];

  @ViewChild('dropdownlistDriverName', { read: ElementRef, static: false })
  driverDll: ElementRef;
  @ViewChild('dropdownlistRideId', { read: ElementRef, static: false })
  rideDll: ElementRef;

  public disabled: boolean = true;
  initialDate: Date;

  TelematicsForm: FormGroup;

  //#region Constructor and ngOnInit
  constructor(
    private _ridesService: RidesService,
    private _appComponent: AppComponent,
    private _driversService: DriversService,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    let that = this;
    that.changeColor = false;
    let telematicsForm: IForm<TelematicsDataFormModel> = {
      dateOfService: [undefined, [Validators.required]],
      rideId: [undefined, [Validators.required]],
      driverId: [undefined, [Validators.required]],
    };
    that.TelematicsForm = that._formBuilder.group(telematicsForm);
    let dataToFilter: any = that.rideData;
    if (Object.keys(that.rideData).length > 0) {
      that.TelematicsForm.setValue({
        dateOfService: new Date(that.rideData.dateOfService),
        rideId: that.rideData.rideId,
        driverId: that.rideData.driverId,
      });
    } else if (that.TelematicsForm.valid) {
      dataToFilter = that.TelematicsForm.getRawValue();
    } else {
      that.getDriverList(BigInt(0));
    }
    if (Object.keys(dataToFilter).length > 0) {
      this.getRideDetails(dataToFilter);
    }
  }
  //#endregion

  get DateOfService() {
    return this.TelematicsForm.get('DateOfService')!;
  }

  get RideId() {
    return this.TelematicsForm.get('RideId')!;
  }

  get DriverId() {
    return this.TelematicsForm.get('DriverId')!;
  }

  private isValidDateFormat(dateString: string): boolean {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    return datePattern.test(dateString);
  }

  public onDateChange(detectedDate: Date): void {
    let that = this;
    const date = detectedDate ? detectedDate.toLocaleDateString('en-CA') : '';
    const isSelectedDateValid = that.isValidDateFormat(date);

    if (isSelectedDateValid) {
      that.TelematicsForm.get('DateOfService')?.setValue(
        that.createDateAsUTC(detectedDate),
      );
    }
  }
  public Search(): void {
    let that = this;
    if (that.TelematicsForm.valid) {
      let dataToFilter = that.TelematicsForm.getRawValue();
      that.getRideDetails(dataToFilter);
    }
  }

  public Clear(): void {
    let that = this;
    that.changeColor = false;
    that.TelematicsForm.reset();
    that.TelematicsForm.get('DateOfService')?.setValue('');
    that.rideDetailsResult = new RideDetail();
    that.rideData = new Ride();
    this.driverDll.nativeElement.classList.remove('ng-dirty');
    this.rideDll.nativeElement.classList.remove('ng-dirty');
  }

  public getRideDetails(filter: Ride) {
    let that = this;
    filter.dateOfService = !(filter.dateOfService instanceof Date)
      ? that.createDateAsUTC(new Date(filter.dateOfService))
      : that.createDateAsUTC(filter.dateOfService);

    that._appComponent.loaderComponent.toggleLoaderVisibility();
    that._ridesService
      .getRideDetails({
        rideId: filter.rideId,
        driverId: filter.driverId,
        dateOfService: filter.dateOfService,
      })
      .subscribe({
        next: (response: any) => {
          this.rideDetailsResult = response.data;
          if (response.data) {
            this.rideData.dateOfService = new Date(
              this.rideDetailsResult.dateOfService,
            );
            that.initialDate = new Date(this.rideDetailsResult.dateOfService);
            this.getRideCoordinates(filter);
          }
          this.getDriverList(filter.driverId);
        },
        error: (error: HttpErrorResponse) => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
        complete: () => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
      });
  }

  public getRideCoordinates(filter: Ride) {
    let that = this;
    that._appComponent.loaderComponent.toggleLoaderVisibility();
    that._ridesService.getRideCoordinates(filter.rideId).subscribe({
      next: (response: any) => {
        if (response.data) {
          this.telematicsSectionMap.basicRideCoordinateDto = response.data;
          this.telematicsSectionMap.options = {
            center: {
              lat: this.telematicsSectionMap.basicRideCoordinateDto
                .startCoordinate.lat,
              lng: this.telematicsSectionMap.basicRideCoordinateDto
                .endCoordinate.lng,
            },
            zoom: 11.7,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
          };
          let startLatLngLiteral: google.maps.LatLngLiteral = {
            lat: this.telematicsSectionMap.basicRideCoordinateDto
              .startCoordinate.lat,
            lng: this.telematicsSectionMap.basicRideCoordinateDto
              .startCoordinate.lng,
          };
          let endLatLngLiteral: google.maps.LatLngLiteral = {
            lat: this.telematicsSectionMap.basicRideCoordinateDto.endCoordinate
              .lat,
            lng: this.telematicsSectionMap.basicRideCoordinateDto.endCoordinate
              .lng,
          };
          let markers: Array<any> = [];
          for (let item of this.telematicsSectionMap.basicRideCoordinateDto
            .rideEventDetailCoordinates) {
            let iconData = this.icons.filter(
              a => a.typeId == item.eventTypeId,
            )[0];
            let mapLatLngLiteral: google.maps.LatLngLiteral = {
              lat: item.lat,
              lng: item.lng,
            };
            let mapMark = {
              position: mapLatLngLiteral,
              icon: iconData != null ? iconData.icon : '',
              time: item.time,
              location: '',
            };
            markers.push(mapMark);
          }
          markers.push({
            position: startLatLngLiteral,
            icon: {
              url: 'assets/images/icons/map-start.png',
              scaledSize: new google.maps.Size(35, 70),
            },
          });
          markers.push({
            position: endLatLngLiteral,
            icon: {
              url: 'assets/images/icons/map-end.png',
              scaledSize: new google.maps.Size(35, 70),
            },
            zoom: 10,
          });
          this.telematicsSectionMap.markers = markers;
          this.telematicsSectionMap.showData = true;
        }
      },
      error: (error: HttpErrorResponse) => {
        this._appComponent.loaderComponent.toggleLoaderVisibility();
      },
      complete: () => {
        this._appComponent.loaderComponent.toggleLoaderVisibility();
      },
    });
  }

  public getRideEventValue(eventTypeId: number) {
    let value: number = 0;
    let data = this.rideDetailsResult?.rideEvents.filter(
      a => a.eventTypeId == eventTypeId,
    )[0];
    if (data) {
      value = data.percentage;
    }
    return value;
  }

  private getDriverList(driverId: bigint): void {
    if (driverId != BigInt(0)) {
      this.changeColor = true;
    }
    this._appComponent.loaderComponent.toggleLoaderVisibility();
    this._driversService
      .syncDriversList()
      .pipe(map((res: DriversListData) => res.data))
      .subscribe({
        next: (response: DriversList[]) => {
          this.driversList = response;
          this.getRideList(driverId);
        },
        error: (error: HttpErrorResponse) => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
      });
  }

  public getRidesByDriver(driverId: bigint): void {
    this.driverDll.nativeElement.classList.add('ng-dirty');
    this._appComponent.loaderComponent.toggleLoaderVisibility();
    this.TelematicsForm.get('rideId')?.setValue(null);
    this.rideDll.nativeElement.classList.remove('ng-dirty');
    this.getRideList(driverId);
  }

  public triggerChange(): void {
    this.rideDll.nativeElement.classList.add('ng-dirty');
  }

  private getRideList(driverId: bigint): void {
    if (driverId == BigInt(0)) {
      this._appComponent.loaderComponent.toggleLoaderVisibility();
      return;
    }
    this._ridesService
      .syncRidesListByDriver(driverId)
      .pipe(map((res: RidesListData) => res.data))
      .subscribe({
        next: (response: RideList[]) => {
          this.rideList = response;
        },
        error: (error: HttpErrorResponse) => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
        complete: () => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
      });
  }

  get isShow(): boolean {
    return Object.keys(this.rideData).length <= 0;
  }

  private createDateAsUTC(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
  }
}
