<ng-container
  *ngIf="
    isFullStyle == true;
    then TemplateWithHeader;
    else TemplateWithoutHeader
  "
>
</ng-container>

<ng-template #TemplateWithHeader>
  <div [ngClass]="IsSideMenuActive ? 'main-container' : ''">
    <div
      class="header"
      *ngIf="IsSideMenuActive"
    >
      <app-header
        *ngIf="currentUser"
        (sidebarToggled)="onSidebarToggled()"
      ></app-header>
    </div>
    <div [ngClass]="IsSideMenuActive ? 'content-container ' : ''">
      <div
        class="sidebar"
        [ngClass]="isSidebarExpanded ? 'sidebar-expanded' : 'sidebar-closed'"
        *ngIf="IsSideMenuActive"
      >
        <app-side-bar
          *ngIf="currentUser"
          class="side-bar-tag"
          #sidebar
        ></app-side-bar>
      </div>
      <div [ngClass]="IsSideMenuActive ? 'router-outlet' : ''">
        <div
          [ngClass]="isSidebarExpanded ? 'router-lg-view' : 'router-sm-view'"
        >
          <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #TemplateWithoutHeader>
  <div class="main-container">
    <div
      class="content-container"
      style="padding-top: 0px !important"
    >
      <div class="router-outlet">
        <router-outlet (activate)="changeOfRoutes()"></router-outlet>
      </div>
    </div>
  </div>
</ng-template>
