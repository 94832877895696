<div
  class="buttonContainer"
  (click)="showMenu = !showMenu"
>
  <button
    class="btn"
    [class.open-menu]="showMenu"
  >
    <span
      class="tab-name"
      [class.open-menu]="showMenu"
      >{{ data.name }}</span
    >
    <div
      class="arrow"
      [class.open-menu]="showMenu"
      *ngIf="data.subItems && data.subItems.length > 0"
    >
      <svg-icon
        src="assets/images/icons/{{ showMenu ? 'upArrow' : 'downArrow' }}.svg"
        [svgStyle]="{ 'width.px': 32.4 }"
      ></svg-icon>
    </div>
  </button>
  <div
    class="headerMenuContainer"
    *ngIf="showMenu && data.subItems && data.subItems.length > 0"
  >
    <ul class="menu">
      <li
        *ngFor="let item of data.subItems"
        tabindex="0"
        (mouseenter)="item.showSubItems = true"
        (mouseleave)="item.showSubItems = false"
      >
        <a
          href="#"
          class="menu-link"
          (click)="toggleSubMenu(item)"
        >
          <span class="menu-text">{{ item.text }}</span>
          <i
            class="fa"
            [ngClass]="{
              'fa-angle-down': item.showSubItems,
              'fa-angle-right': !item.showSubItems
            }"
            *ngIf="item.subItems"
          ></i>
        </a>
        <ul
          class="submenu"
          [ngStyle]="{ display: item.showSubItems ? 'block' : 'none' }"
        >
          <li *ngFor="let subItem of item.subItems">
            <a
              href="#"
              class="menu-link"
            >
              <span class="menu-text">{{ subItem.text }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
