import { Location } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SCALE } from '@progress/kendo-angular-popup';
import { AppComponent } from 'src/app/app.component';
import { CoreMenuItem } from 'src/app/interfaces/core-menu-data/core-menu-data.interface';
import { IDropDownListModel } from 'src/app/interfaces/shared/drop-down-list-data.interface';
import { AboutDialogComponent } from 'src/app/layout/Components/aboutDialog/about-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [{ provide: SCALE, useValue: 0.8 }],
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'startsWith',
  };
  //About Dialog
  @ViewChild(AboutDialogComponent) aboutDialogComponent!: AboutDialogComponent;

  // menu variables
  public currentMenu: CoreMenuItem;
  @Output() sidebarToggled = new EventEmitter();
  collapsed = true;
  isSidebarExpanded = false;
  isHovering = false;

  // location and region dropdown
  @ViewChild('regionsDropdownlist', { static: true }) public dropdownlist: any;
  @ViewChild('locationDropdownlist', { static: true })
  public locationDropdownlist: any;
  public locations: IDropDownListModel[];
  public isLocationListDisabled = false;
  public regions: IDropDownListModel[];
  public isRegionsListDisabled = false;
  public selectedLocation: IDropDownListModel;
  public selectedRegion: IDropDownListModel;

  public currentPath: string;

  constructor(
    private _authenticationService: AuthenticationService,
    private _appComponent: AppComponent,
    private router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    window.addEventListener('changeMenu', () => {
      const currentMenu = sessionStorage.getItem('currentMenu');
      if (currentMenu) {
        this.currentMenu = JSON.parse(currentMenu) as CoreMenuItem;
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentPath = this.location.path();
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setHeaderActiveMenu();
    }, 500);
  }

  getCurrentUserLocations() {
    let locations =
      this._authenticationService.currentUserValue.Locations.filter(
        location => location.regionId === this.selectedRegion.id,
      );
    return locations.sort((a, b) => a.name.localeCompare(b.name));
  }

  public convertDateToUtc(date: Date) {
    const dateNew = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      ),
    );

    return dateNew;
  }

  public setHeaderActiveMenu() {
    const pathname = window.location.pathname;
    const sidebar = this._appComponent.mainLayoutComponent.sidebar!;
    let menuId: string | undefined;

    if (pathname.includes('home')) {
      menuId = 'home';
    } else if (
      pathname.includes('districts') ||
      pathname.includes('schools') ||
      pathname.includes('requests') ||
      pathname.includes('reports')
    ) {
      menuId = 'Customers';
    } else if (pathname.includes('passengers')) {
      menuId = 'Passengers';
    } else if (pathname.includes('drivers')) {
      menuId = 'Drivers';
    } else if (pathname.includes('Messaging')) {
      menuId = 'Messaging';
    } else if (
      pathname.includes('settings') ||
      pathname.includes('RegionsAndLocations')
    ) {
      menuId = 'Settings';
    }

    if (menuId && sidebar) {
      let menu = sidebar.MainMenu?.find(item => item.id === menuId);
      if (!menu) {
        // If not found in MainMenu, check SystemMenu
        menu = sidebar.SystemMenu.find(item => item.id === menuId);
      }

      if (menu) {
        sidebar.reflectHeaderMenu(menu, pathname);
      }
    }
  }

  getSidebarIcon() {
    if (this.isSidebarExpanded) {
      return this.isHovering ? 'openHover' : 'openSideBar';
    } else {
      return this.isHovering ? 'closeHover' : 'closeSideBar';
    }
  }

  toggleSidebar() { 
    this.isSidebarExpanded = !this.isSidebarExpanded;
    this.sidebarToggled.emit();
  }

  Logout() {
    this.toggleSidebar();
    this._authenticationService.logout();
  }

  openAbout() {
    this.aboutDialogComponent.open();
  }
}
