import { Component, ElementRef, HostListener, Input } from '@angular/core';

export interface MenuItem {
  name: string;
  text: string;
  icon?: string;
  subItems?: MenuItem[];
  showSubItems?: boolean;
}

@Component({
  selector: 'app-header-menus',
  templateUrl: './header-menus.component.html',
  styleUrls: ['./header-menus.component.css'],
})
export class HeaderMenusComponent {
  @Input() data: MenuItem;
  constructor(private elRef: ElementRef) {
    this.data = {
      name: '',
      text: '',
      subItems: [],
    };
  }
  showMenu = false;

  onToggleMenu() {
    this.showMenu = !this.showMenu;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }
  @Input() items: MenuItem[] = [];

  toggleSubMenu(item: MenuItem) {
    if (item.subItems) {
      item.showSubItems = !item.showSubItems;
    }
  }
}
