<ul class="menu">
  <li
    *ngFor="let item of items"
    tabindex="0"
    (mouseenter)="item.openTab = true"
  >
    <a
      href="javascript:void(0);"
      (click)="gotoPage(item)"
      class="menu-link"
      (click)="toggleSubMenu(item)"
    >
      <span class="menu-text">{{ item.title }}</span>
      <i
        class="fa"
        [ngClass]="{
          'fa-angle-down': item.openTab,
          'fa-angle-right': !item.openTab
        }"
        *ngIf="item.children"
      ></i>
    </a>
    <ul
      class="submenu"
      [ngStyle]="{ display: item.openTab ? 'block' : 'none' }"
    >
      <li *ngFor="let subItem of item.children">
        <a
          href="javascript:void(0);"
          (click)="gotoPage(subItem)"
          class="menu-link"
        >
          <span class="menu-text">{{ subItem.title }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>
