export enum PermissionsList {
  OverviewView = 1,
  OverviewCreate = 2,
  OverviewEdit = 3,
  OverviewDelete = 4,
  OverviewArchive = 5,

  TrackingView = 6,
  TrackingCreate = 7,
  TrackingEdit = 8,
  TrackingDelete = 9,
  TrackingArchive = 10,

  TelematicsView = 11,
  TelematicsCreate = 13,
  TelematicsEdit = 14,
  TelematicsDelete = 15,
  TelematicsArchive = 16,

  SettingView = 17,
  SettingCreate = 18,
  SettingEdit = 19,
  SettingDelete = 20,
  SettingArchive = 21,
}
