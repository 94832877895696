<div
  class="menu-wrapper"
  (mouseenter)="onHover()"
  (mouseleave)="onLeave()"
  (click)="toggleMenu()"
>
  <button
    class="btn"
    (click)="gotoPage()"
  >
    <div class="sid-bar-tab">
      <div class="icon">
        <app-svg-renderer
          [icon]="icon"
          [class]="'icon'"
          [width]="24"
          [height]="24"
        ></app-svg-renderer>
      </div>
      <span
        class="tab-name"
        *ngIf="showName"
        >{{ name }}</span
      >
    </div>
    <div
      class="arrow"
      *ngIf="showName && menuItems && menuItems.length > 0"
    >
      <i class="fa fa-angle-right"></i>
    </div>
  </button>
  <div
    class="menu-container"
    *ngIf="showMenu && menuItems && menuItems.length > 0"
    (mouseenter)="onHover()"
    (click)="showMenu = true"
  >
    <app-side-bar-menu [items]="menuItems"></app-side-bar-menu>
  </div>
</div>
